import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox,
    Pagination,
    Modal,
    Tabs
} from "antd";

import {
    SearchOutlined, FileExcelOutlined, MoreOutlined
} from "@ant-design/icons"
import { default1 } from '../../../components/icons';
import requestRow from '../../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const PayNext = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div className="row mb-3">
                <div className="col-lg-8">
                    <div style={{ display: 'flex' }}>
                        <h6 style={{ marginRight: 10, cursor: "pointer" }}>Vendors payout</h6>
                    </div>
                    <p>List of pay on next order</p>
                </div>

                <div className="col-lg-2"></div>
                <div className="col-lg-2 mb-0">
                    <Input size="large" placeholder="Searh here..." prefix={<SearchOutlined />} />
                </div>

            </div>

            <table style={{
                border: '1px solid black',
                width: '100%',
                borderColor: '#F2F2F2'
            }}>
                {
                    isMatch ? (
                        <>

                        </>
                    ) : (
                        <>
                            <tbody >
                                <tr style={{ border: '1px solid #F0F0F0' }}>
                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <input type="text" className="form-control" placeholder="Operator name" />
                                        </div>
                                    </td>
                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <select className="form-control">
                                                <option value="">Select Service</option>
                                            </select>
                                        </div>
                                    </td >
                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <select className="form-control">
                                                <option value="">Select Status</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <select className="form-control">
                                                <option value="">Select country</option>
                                            </select>
                                        </div>

                                    </td>
                                </tr>
                                <tr style={{ border: '1px solid #F0F0F0' }}>
                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <select className="form-control">
                                                <option value="">Call from Api</option>
                                            </select>
                                        </div>
                                    </td>

                                    <td style={{ border: '1px solid #F0F0F0' }}>
                                        <div className="inputBox m-1">
                                            <Button
                                                style={{
                                                    background: '#4691EE', color: 'white', borderColor: '#39A945',
                                                    marginRight: 5
                                                }}
                                                icon={<SearchOutlined />}>
                                                Filter
                                            </Button>

                                            <Button style={{
                                                background: '#39A945', color: 'white', borderColor: '#39A945',

                                            }} icon={<FileExcelOutlined />}>Export</Button>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </>
                    )
                }

            </table>

            <div className='mt-4'>
                <div className="table-responsive">
                    <table className="table header-border table-responsive-sm tbl-common">
                        <thead style={{
                            borderLeft: '2px 2px solid #cfcfcf',
                            borderBottom: '1px solid #E8E8E8'
                        }}>
                            <tr>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Partener </th>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Type </th>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Transaction#</th>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Invoice Date</th>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Paid </th>
                                <th scope="col" style={{ paddingLeft: 10, borderLeft: '0px solid #ffffff', }}>Receipt </th>
                                <th scope="col" style={{ padding: 10, borderLeft: '0px solid #ffffff', }}>Status </th>
                            </tr>

                        </thead>

                        <tbody>
                            <tr style={{ fontSize: 14 }}>

                                <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />Luka S. James</td>
                                <td>Vondor</td>
                                <td>1358864839274</td>

                                <td>Feb 24, 2023</td>

                                <td>
                                    <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                        <label style={{ marginTop: 8 }}>Tsh. 10 000.00</label>
                                    </Badge>
                                </td>

                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 60
                                        }}>
                                        Print
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 80,
                                        }}>
                                        PAID
                                    </Button>
                                </td>

                            </tr>
                            <tr style={{ fontSize: 14 }}>

                                <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />Vicky S. Juma</td>
                                <td>Vondor</td>
                                <td>1358864839090</td>

                                <td>Feb 24, 2023</td>

                                <td>
                                    <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                        <label style={{ marginTop: 8 }}>Tsh. 30 000.00</label>
                                    </Badge>
                                </td>

                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 60
                                        }}>
                                        Print
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 80,
                                        }}>
                                        PAID
                                    </Button>
                                </td>

                            </tr>
                            <tr style={{ fontSize: 14 }}>

                                <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />Salome S. Vuma</td>
                                <td>Vondor</td>
                                <td>13588648390081</td>

                                <td>Feb 24, 2023</td>

                                <td>
                                    <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                        <label style={{ marginTop: 8 }}>Tsh. 2000.00</label>
                                    </Badge>
                                </td>

                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 60
                                        }}>
                                        Print
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 80,
                                        }}>
                                        PAID
                                    </Button>
                                </td>

                            </tr>
                            <tr style={{ fontSize: 14 }}>

                                <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />Naomie S. Luna</td>
                                <td>Vondor</td>
                                <td>13588648392003</td>

                                <td>Feb 24, 2023</td>

                                <td>
                                    <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                        <label style={{ marginTop: 8 }}>Tsh. 50 000.00</label>
                                    </Badge>
                                </td>

                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 60
                                        }}>
                                        Print
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 80,
                                        }}>
                                        PAID
                                    </Button>
                                </td>

                            </tr>
                            <tr style={{ fontSize: 14 }}>

                                <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />Juma S. Homa</td>
                                <td>Vondor</td>
                                <td>1358864839200</td>

                                <td>Feb 24, 2023</td>

                                <td>
                                    <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                        <label style={{ marginTop: 8 }}>Tsh. 70 000.00</label>
                                    </Badge>
                                </td>

                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 60
                                        }}>
                                        Print
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        style={{
                                            height: 25,
                                            padding: 0,
                                            width: 80,
                                        }}>
                                        PAID
                                    </Button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default PayNext