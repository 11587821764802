import React, { Children, useEffect, useState } from 'react'
import { avatar1, french, italy, logoLight, logoSm, russia, spain, us } from '../icons'
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { Button, Drawer, Radio, Space, Menu, } from 'antd';
import {
    MenuOutlined,
    AppstoreOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    SnippetsOutlined,
    NodeCollapseOutlined,
    SolutionOutlined,
    ProjectOutlined
} from '@ant-design/icons';
import Sider from 'antd/lib/layout/Sider';
import requestRow from '../../controller/RequestRow';
import { Tooltip } from 'react-tooltip';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";


interface CustomTooltipProps {
    message: string;
  }

// import './header.css'
const Header = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    let tempo: any = [];

    const [load, setLoad] = useState<requestRow[]>([])
    const [countNotification, setCountNotification] = useState('');

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    function logout() {
        api.getHttpRequest('/user/profile/login-out', {}, 'POST').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                ;
                sessionStorage.clear();
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })

    }

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    /** Usse Menu ant */

    const location = useLocation()
    const [selectedKeys, setSelectedKeys] = useState('/')

    useEffect(() => {
        const pathName = location.pathname
        setSelectedKeys(pathName)
        LoadNotification()
    }, [location.pathname])


    const LoadNotification = () => {
        api.getHttpRequest(`/user/notifications`, data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoad(json.data.rows);
                setCountNotification(json.data.count);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const DesibleNotification = (id: any) => {
        if (!tempo.includes(id)) {
            api.getHttpRequest(`/user/notifications/view/${id}`, {}, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    tempo.push(id);
                }
            }).catch((error) => {
                toast.error(error.message);
            })
        }

    }

    const DeleteNotification = () => {
        api.getHttpRequest(`/user/notifications/clear`, {}, 'DELETE').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                LoadNotification()
            }
        }).catch((error) => {
            toast.error(error.message);
        })
    }

    const getNotificationIcon = (x: any) => {
        if (x == 1) return ['notify-icon bg-success', 'mdi mdi-comment-account-outline'];
        if (x == 0) return ['notify-icon bg-danger', 'mdi mdi-comment-account-outline'];
        return '';
    }


    function CustomTooltip({ message } : CustomTooltipProps) {
        const [showTooltip, setShowTooltip] = useState(false);

        return (
            <div
                className="custom-tooltip"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                 {
                isMatch ? (
                    <>
                      <p>{message.substring(0, 15)}...</p>
                    </>
                ):(
                    <>
                      <p>{message.substring(0, 40)}...</p>
                    </>
                )
            }
            
                {showTooltip && <div className="text-muted" style={{ whiteSpace: 'pre-wrap' }}>{message}</div>}
            </div>
        );
    }

    return (
        <>
            <header id="topnav">

                <div className="navbar-custom">
                    <div className="container-fluid1">
                        <ul className="list-unstyled topnav-menu float-right mb-0">

                            <li className="dropdown notification-list">
                                {/** Mobile menu toggle */}
                                {/* <a className="navbar-toggle nav-link" onClick={showDrawer}>
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a> */}

                                {/** End mobile menu toggle */}
                            </li>


                            <li className="dropdown d-none d-lg-block">
                                <a className="nav-link dropdown-toggle mr-0" data-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="false" aria-expanded="false">
                                    <img src={us} alt="user-image" height="12" /> <span
                                        className="align-middle d-none d-xl-inline-block ml-2">English <i
                                            className="mdi mdi-chevron-down"></i> </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">

                                    <a href="" className="dropdown-item notify-item">
                                        <img src={spain} alt="user-image" className="mr-2" height="12" />
                                        <span className="align-middle">Spanish</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={italy} alt="user-image" className="mr-2" height="12" />
                                        <span className="align-middle">Italian</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={french} alt="user-image" className="mr-2" height="12" />
                                        <span className="align-middle">French</span>
                                    </a>


                                    <a href="" className="dropdown-item notify-item">
                                        <img src={russia} alt="user-image" className="mr-2" height="12" />
                                        <span className="align-middle">Russian</span>
                                    </a>
                                </div>
                            </li>

                            <li className="dropdown notification-list">
                                <a className="nav-link dropdown-toggle mr-0" data-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="false" aria-expanded="false">
                                    <i className="mdi mdi-bell noti-icon"></i>
                                    <span className="badge badge-danger rounded-circle noti-icon-badge">{countNotification}</span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                                    <div className="dropdown-item noti-title">
                                        <h5 className="font-16 m-0">
                                            <span className="float-right">
                                            <div onClick={() => DeleteNotification()} style={{cursor:'pointer'}} className="text-dark">
                                                    <small>Clear All</small>
                                                </div>
                                            </span>Notification
                                        </h5>
                                    </div>

                                    <div className="slimscroll noti-scroll">

                                    {load.map((item, i) => (
                                            <div onMouseEnter={() => DesibleNotification(item.id)} key={i} className="dropdown-item notify-item">
                                                <div className={getNotificationIcon(item.is_alert)[0]}>
                                                    <i className={getNotificationIcon(item.is_alert)[1]}></i>
                                                </div>
                                                <CustomTooltip message={item.message} />
                                                <small className="text-muted">
                                                    {api.geDateFormat(item.createdAt)}
                                                </small>
                                            </div>
                                        ))}
                                        
                                    </div>
{/* 
                                    <a href=""
                                        className="dropdown-item text-primary text-center notify-item notify-all">
                                        View all
                                        <i className="fi-arrow-right"></i>
                                    </a> */}

                                </div>
                            </li>

                            <li className="dropdown notification-list">
                                <a className="nav-link dropdown-toggle nav-user mr-0" data-toggle="dropdown" href="#"
                                    role="button" aria-haspopup="false" aria-expanded="false">
                                    <img src={api.var_.url_avatar + api.initAvatar()} alt="user-image" className="rounded-circle" />
                                    <span className="pro-user-name d-none d-xl-inline-block ml-2">
                                        {api.initUser()} <i className="mdi mdi-chevron-down"></i>
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">

                                    <div className="dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Welcome !</h6>
                                    </div>


                                    <Link to="/user-profile" className="dropdown-item notify-item">
                                        <i className="mdi mdi-account-outline"></i>
                                        <span>Profile</span>
                                    </Link>


                                    <Link to="/security-parameters" className="dropdown-item notify-item">
                                        <i className="mdi mdi-settings-outline"></i>
                                        <span>Security parameters </span>
                                    </Link>



                                    <div className="dropdown-divider"></div>


                                    <Link to="/login" onClick={logout} className="dropdown-item notify-item">
                                        <i className="mdi mdi-logout-variant"></i>
                                        <span>Logout</span>
                                    </Link>

                                </div>
                            </li>



                        </ul>

                        {/** LOGO */}
                        <div className="logo-box">

                            <div className="logo text-center">

                            </div>
                            <div className="logo text-center">

                                <span className="logo-lg">
                                    <MenuOutlined onClick={showDrawer} style={{ fontSize: 30, color: '#A1C8F8', marginRight: 28, marginLeft: 20, cursor: 'pointer' }} />
                                    <img src={logoSm} alt="" height="40" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }} />
                                    {/** <span className="logo-lg-text-light">Simple</span> */}

                                </span>
                                <span className="logo-sm">
                                    <MenuOutlined onClick={showDrawer} style={{ fontSize: 25, color: '#A1C8F8', marginRight: 10, marginLeft: 5, cursor: 'pointer' }} />
                                    {/**  <span className="logo-sm-text-dark">S</span> */}
                                    <img src={logoSm} alt="" height="36" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }} />
                                </span>
                            </div>
                        </div>

                        <div id="navigation" >
                            <div className="input-group search-area d-lg-inline-flex" style={{ marginTop: 18 }}>
                                <div className="input-group-append">
                                    <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                            </div>

                            {/* <ul className="navigation-menu">

                                <li className="has-submenu">
                                    <Link to="/home"> <i className="ti-home"></i> Dashboard <div className="arrow-down"></div>
                                    </Link>
                                    <ul className="submenu">
                                        <li><Link to="/home">Home</Link></li>
                                        <li><Link to="/add-payment-method">Add Payout Methods</Link></li>
                                    </ul>
                                </li>


                                <li className="has-submenu"><Link to="/member"> <i className="icon-user"></i>Members</Link> </li>
                                <li className="has-submenu"><Link to="/invetory"> <i className="mdi mdi-ticket"></i> Inventory</Link>
                                </li>
                                <li className="has-submenu"><Link to="/transaction"> <i className="mdi mdi-ticket"></i> Transactions</Link>
                                </li>
                                <li className="has-submenu"><Link to="/list_of_seller"> <i className="mdi mdi-format-list-bulleted"></i>
                                    Sellers</Link></li>


                            </ul>
                            <div className="clearfix"></div> */}
                        </div>


                        <div className="clearfix"></div>
                    </div>
                </div>

            </header>

            <Drawer
                title={<img src={logoLight} alt="" height="40" />}
                placement={'left'}
                width={252}
                closeIcon={<MenuOutlined style={{ fontSize: 25, color: '#1576ED' }} />}
                open={open}
                onClose={onClose}
                zIndex={1100}
            >

                <div className="deznav" style={{ marginLeft: 0 }}>
                    <div className="deznav-scroll">
                        {/* <ul className="metismenu" id="menu">
                            <li><Link to="/home" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="ti-home" style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Home</span>
                            </Link>
                            </li>
                            <li><Link to="/add-payment-method" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="ti-home" style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Add Payout Methods</span>
                            </Link>
                            </li>

                            <li><Link to="/member" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="icon-user" style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Members</span>
                            </Link>
                            </li>

                            <li><Link to="/invetory" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="mdi mdi-ticket " style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Inventory</span>
                            </Link>
                            </li>

                            <li><Link to="/transaction" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="mdi mdi-ticket " style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Transactions</span>
                            </Link>
                            </li>

                            <li><Link to="/list_of_seller" className="has-arrow ai-icon" onClick={onClose}>
                                <i className="mdi mdi-format-list-bulleted " style={{ fontSize: 25 }}></i>
                                <span className="nav-text" style={{ fontSize: 25 }}>Sellers</span>
                            </Link>
                            </li>




                        </ul> */}


                        <Sider collapsedWidth={0} >
                            {/* <Menu style={{ paddingLeft: 0 }} onSelect={() => onClose()} >
                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }}
                                    key="/home" icon={<AppstoreOutlined style={{ fontSize: 25 }} />}><Link to="/home" style={{ fontSize: 18 }}>Home</Link></Menu.Item>

                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }} key="/add-payment-method" icon={<ShopOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/add-payment-method" style={{ fontSize: 18 }}>Add Payout Methods</Link>
                                </Menu.Item>
                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }} key="/member" icon={<UserOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/member" style={{ fontSize: 18 }}>Members</Link>
                                </Menu.Item>
                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }} key="/assign" icon={<ProjectOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/voucher-assign" style={{ fontSize: 18 }}>Vouchers Assigned</Link>
                                </Menu.Item>
                                <Menu.Item key="/inventory" icon={<SnippetsOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/inventory" style={{ fontSize: 18 }}>Inventory</Link>
                                </Menu.Item>
                                
                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }} key="/transaction" icon={<NodeCollapseOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/transaction" style={{ fontSize: 18 }}>Transactions</Link>
                                </Menu.Item>
                                <Menu.Item style={{ color: 'black', margin: 5, paddingTop: 5 }} key="/list_of_seller" icon={<SolutionOutlined style={{ fontSize: 25 }} />}>
                                    <Link to="/list_of_seller" style={{ fontSize: 18 }}>Sellers</Link>
                                </Menu.Item>
                            </Menu> */}

                            <Menu
                                onSelect={() => onClose()}
                                onClick={(item) => {
                                    navigate(item.key)
                                }}

                                selectedKeys={[selectedKeys]}
                                mode="inline"
                                items={[
                                    {
                                        label: 'Dashboard',
                                        key: '/',
                                        icon: <AppstoreOutlined style={{ fontSize: 25 }} />,
                                        children: [
                                            { label: "Home", key: "/home" },
                                            { label: "Payout Methods", key: "/add-payment-method", icon: <ShopOutlined style={{ fontSize: 15 }} /> },
                                        ],
                                    },
                                    {
                                        label: "Members",
                                        key: "/member",
                                        icon: <UserOutlined style={{ fontSize: 25 }} />
                                    },
                                    // {
                                    //     label:"Series Assigned",
                                    //      key:"/voucher-assign",
                                    //      icon:<ProjectOutlined style={{ fontSize: 25 }} />
                                    // },
                                    {
                                        label: "Inventory",
                                        key: "/voucher",
                                        icon: <SnippetsOutlined style={{ fontSize: 25 }} />,
                                        children: [
                                            { label: "Vouchers Assigned", key: "/invetory" },
                                            { label: "In Your Stock", key: "/stock" }
                                        ]
                                    },
                                    {
                                        label: "Transactions",
                                        key: "/transaction",
                                        icon: <NodeCollapseOutlined style={{ fontSize: 25 }} />
                                    },
                                    {
                                        label: "List of Sellers",
                                        key: "/list_of_seller",
                                        icon: <SolutionOutlined style={{ fontSize: 25 }} />
                                    }


                                ]}
                            ></Menu>
                        </Sider>
                    </div>
                </div>



            </Drawer>

        </>
    )
}

export default Header


