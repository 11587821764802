import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Home from '../../views/Home'
import Payment from '../../views/payement'
import Member from '../../views/member'
import VoucherDistribution from '../../views/vouchers'
import Sellers from '../../views/sellers'
import SellerAccount from '../../views/sellers/sellerAcount'
import ProfilSeller from '../../views/profil'
import SecurityParams from '../../views/profil/settings'
import Transaction from '../../views/transaction'
import VoucherAssigned from '../../views/assignedvoucher'
import Stock from '../../views/vouchers/Stock'
import VendorHome from '../../views/vendor/venderHome'
import Pay from '../../views/vendor/pay'
import AddPayementVendor from '../../views/vendor/addpayement'
import StockVendor from '../../views/vendor/vendorStock'
import PaymentHistory from '../../views/vendor/pay/historyPay'

const AppRoutes = () => {
  let navigate = useNavigate();
  useEffect(() => {
      if(sessionStorage.getItem("onktxonktx") === null) {
          navigate("/login");
        }
  },[])
  return (
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route  path='/home' element={<Home/>} />
        <Route path='/add-payment-method' element={<Payment/>} />
        <Route path='/member' element={<Member/>}/>
        <Route path='/invetory' element={<VoucherDistribution/>} />
        <Route path='/transaction' element={<Transaction />} />
        <Route path='/list_of_seller' element={<Sellers/>} />
        <Route path='/seller_account/:id_sellers' element={<SellerAccount/>} />
        <Route path='/user-profile' element={<ProfilSeller/>} />
        <Route path='/security-parameters' element={<SecurityParams/>} />
        <Route path='/voucher-assign' element={<VoucherAssigned/>} />
        <Route path='/stock' element={<Stock/>} />
        <Route path='/vendor' element={<VendorHome/>} />
        <Route path='/pay' element={<Pay/>} />
        <Route path='/vendor-payment-method' element={<AddPayementVendor/>}/>
        <Route path='/stock-vendor' element={<StockVendor />} />
        <Route path='/payment-history' element={<PaymentHistory/>}/>
    </Routes>
  )
}

export default AppRoutes
