
import { HashRouter, Routes, Route} from 'react-router-dom';
import Login from './views/authentification/login';
import Logup from './views/authentification/Logup';
import VerifyOtp from './views/authentification/Logup/VerifyOtp';
import Forgot from './views/authentification/forgotpass';
import VerifyForgot from './views/authentification/forgotpass/VerifyForgot';
import Container from './components/Container';


function App() {

  return (
     <>
       <HashRouter>
          <Routes>
              <Route index element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/account' element={<Logup />} />
              <Route path='/verify_otp' element={<VerifyOtp />} />
              <Route path='/forgot_pswd' element={<Forgot />} />
              <Route path='/verify_forgot' element={<VerifyForgot/>} />
              <Route  path='*' element={<Container/>} />

          </Routes>
       </HashRouter>
     </>
  );
}

export default App;
