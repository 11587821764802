import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import requestRow from '../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Pagination, Input, Select } from 'antd';
import PhoneInput from 'react-phone-input-2'
const { Option } = Select;

const ProfilSeller = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [province, setPronvince] = useState<requestRow[]>([])
    const [city, setCity] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])

    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [loadprov, setLoadProv] = useState('');
    const [loadity, setLoadCity] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [minicipalName, setMinicipalName] = useState('');
    

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        loadProvince()
        loadCity()
        loadMinicipality()
        LoadPrile()
        loadCategorie_or_Role()
        // Promise.all([loadProvince(), loadCity(), loadMinicipality(), LoadPrile()])
        // .catch((error) => {
        //     if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        //         toast.error("Pas de connexion internet");
        //     } else {
        //         toast.error(error.response.data.message);
        //     }
        // });
    }, []);

    const LoadPrile = async () => {
        api.getHttpRequest("/user/profile/load", data, 'GET').then((response) => {
            
            const json = response.data.data;

            const startDate = new Date(json.date_of_birth);
            const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            
            setDateOfBirth(formattedStartDate)
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setGender(json.gender)
            setAddress(json.address);
            setMinicipalName(json.municipalityName);
            setMinicipal(json.MunicipalityId)
            setAvatar(json.avatar);
            setUserRoleId(json.UserRoleId);
            setLoadProv(json.province)
            setLoadCity(json.city)
            setMinicipal(json.MunicipalityId);
            setPlaceOfBirth(json.place_of_birth)
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    //Update Profile
    const UpdateProfile = async () => {
        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("MunicipalityId", minicipal);
        formData.append("gender", gender);
        formData.append("date_of_birth", dateOfBirth);
        formData.append("place_of_birth", placeOfBirth);

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/user/profile/update',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);
        if (name === '' || lastname === '' || phone === ''
            || email === '' || minicipal === '') {
            toast.error('No fields should be empty');

        } else if (!regexTelephone.test(phone)) {
            toast.error('The entry number is incoreact');

        } else if (!regexEmail.test(email)) {
            toast.error('The email address entered is incoreact');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        toast.success(json.message)
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    //Load zones

    const loadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load?groupe=1", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestRow[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
    }
    const getMinicip = minicipal;
    if (getMinicip !== undefined) {
        const getCti = city.find(city => city.id === getMinicip);
        // const getProv = province.find(province => province.id === getCti.PronvinceId)
        // setPronvince(getProv)
        if (getCti !== undefined) {
            setStateCity(prevState => [...prevState, getCti]);
            setStateCity(prevState => [...prevState, getCti]);
        }
    }

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    // console.log(city);
    return (
        <>
            {/** Start container-fluid */}

            <div className="container-fluid1">

                {/** search-bar and active btn */}
                <div className="row pb-5">
                    <div className="col-lg-8">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                <div className="input-group search-area d-lg-inline-flex">
                                    <div className="input-group-append">
                                        <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search here..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2">
                        <div className="d-flex weather-detail">
                            <span id="invest_status_pan" style={{ backgroundColor: '#1EAAE7', color: 'white' }}><i
                                className="las la-scan"></i> <a id="invest_status">Active</a></span> Status
                        </div>
                    </div>
                </div>
                {/** search-bar and active btn */}

                <div className="col-xl-12 card stacked-2 py-5 pt-2">
                    <div className="row col-sm-12">
                        <div>
                            <Toaster
                                position="bottom-left"
                                reverseOrder={false}

                            />
                        </div>
                        <div className="col-xl-12">
                            <h4 className="fs-20 text-black pb-2">My Profile

                            </h4>
                            <div className="basic-form">

                                {/* <div className="pro-pic mb-5">
                                    <div className="profile-pic-wrapper">
                                        <div className="pic-holder">
                                            <img id="profilePic" className="pic" src={api.var_.url_avatar + avatar} />

                                            <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                                                onChange={handleImageChange}
                                            />
                                            <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                <div className="text-center">
                                                    <div className="mb-2">
                                                        <i className="fa fa-camera fa-2x"></i>
                                                    </div>
                                                    <div className="text-uppercase">
                                                        Update <br /> Profile Photo
                                                    </div>
                                                </div>
                                            </label>
                                        </div>

                                        <hr />
                                    </div>
                                </div>

                                <div className="row clearfix">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">Name</label>
                                        <input type="text" className="form-control" id="name" placeholder="Name"
                                            name='name'
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">Post Name</label>
                                        <input type="text" className="form-control" id="middle_ame" placeholder="Post name"
                                            name='lastname'
                                            value={lastname}
                                            onChange={(e) => { setLastName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="lastname" placeholder="Middle name"
                                            name='middlename'
                                            value={middlename}
                                            onChange={(e) => { setMiddlename(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">Phone</label>
                                        <input type="text" className="form-control" id="phone" placeholder="Phone"
                                            name='phone'
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="Email"
                                            name='email'
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="Province" className="form-label">Province</label>
                                        <select className="form-control" id="Province" placeholder="Email"
                                            onChange={(e) => GetProvince(e.target.value)}
                                        >
                                            <option value=''>Select province</option>
                                            {
                                                province &&
                                                    province !== undefined ?
                                                    province.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                    : "No Province"
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="City" className="form-label">City</label>
                                        <select className="form-control" id="City" placeholder="Email"
                                            onChange={(e) => GetCity(e.target.value)}
                                        >
                                            <option value=''>Select City</option>
                                            {
                                                stateCity &&
                                                    stateCity !== undefined || stateCity !== null ?
                                                    stateCity.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                    : "No City"
                                            }

                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="Minicipality" className="form-label">Minicipality</label>
                                        <select className="form-control" id="Minicipality" placeholder="Email"
                                            name='minicipality'
                                            value={minicipal}

                                            onChange={(e) => { setMinicipal(e.target.value) }}
                                        >
                                            <option value=''>Select Minicipality</option>
                                            {
                                                stateMinicipality &&
                                                    stateMinicipality !== undefined || stateMinicipality !== null ?
                                                    stateMinicipality.map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                    : "No minicipality"
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="adresse" className="form-label">Adresse</label>
                                        <textarea className="form-control" id="adresse" placeholder="Your address"
                                            name='adress'
                                            value={adress}
                                            onChange={(e) => { setAddress(e.target.value) }}
                                        />
                                    </div>
                                 
                                    <div className="form-group col-md-6">
                                        {!Loading && (
                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { UpdateProfile() }}>Update profile</button>
                                        )}

                                        {Loading && (

                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                            </button>
                                        )}
                                    </div>
                                </div> */}


                                <div className="row">

                                    {/* <input type="hidden" id="id" value="00" /> */}


                                    <div className='col-md-12 row'>

                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" placeholder="" id="fullname"
                                                name='name'
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">LastName</label>
                                            <input type="text" className="form-control" placeholder="" id="fullname"
                                                name='lastname'
                                                value={lastname}
                                                onChange={(e) => { setLastName(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">MiddleName</label>
                                            <input type="text" className="form-control" placeholder="" id="fullname"
                                                name='middlename'
                                                value={middlename}
                                                onChange={(e) => { setMiddlename(e.target.value); }}
                                            />
                                        </div>
                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">Category</label>
                                            <select className="form-control" id="categorie"
                                                name='userRoleId'
                                                value={userRoleId}
                                                onChange={(e) => { setUserRoleId(e.target.value) }}
                                            >
                                                <option value="">Select category</option>
                                                {
                                                    categoriRole.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }


                                            </select>
                                        </div>

                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">Gender</label>
                                            <select className="form-control" id="gender"
                                                name='gender'
                                                value={gender}
                                                onChange={(e) => { setGender(e.target.value) }}
                                            >
                                                <option>Choose...</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">Date of birth</label>
                                            <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                name='dateOfBirth'
                                                value={dateOfBirth}
                                                onChange={(e) => { setDateOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-1  col-md-4">
                                            <label className="form-label">Place of Birth</label>
                                            <input type="text" className="form-control" placeholder="" id="phone"
                                                name='placeOfBirth'
                                                value={placeOfBirth}
                                                onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-1  col-md-4">
                                            <label className="form-label">Phone</label>
                                            <PhoneInput
                                                inputStyle={{
                                                    width: '100%',

                                                } as React.CSSProperties}
                                                inputClass={'form-control'}
                                                country={'tz'}
                                                value={phone}
                                                onChange={changePhoneNumber}
                                                enableSearch={true}
                                                countryCodeEditable={true}
                                            />
                                        </div>

                                        <div className="mb-1  col-md-4">
                                            <label className="form-label">Adress Mail</label>
                                            <input type="email" className="form-control" placeholder="" id="email"
                                                name='email'
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value); }}
                                            />
                                        </div>
                                        <div className="mb-1 col-md-4">
                                            <label className="form-label">Province</label>

                                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                                placeholder="Select country" optionFilterProp="children" value={loadprov} onChange={GetProvince}
                                                filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                <Option selected value="">Select State/Region
                                                </Option>
                                                {
                                                    province &&
                                                        province !== undefined ?
                                                        province.map((item, i) => (
                                                            <Option key={i} value={item.id}>{item.name}</Option>
                                                        ))
                                                        : "No Province"
                                                }
                                            </Select>
                                        </div>
                                        <div className="mb-1  col-md-4">
                                            <label className="form-label">District</label>

                                            <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}

                                                placeholder="Select country" optionFilterProp="children" value={loadity} onChange={GetCity}
                                                filterOption={(input: string, option: any) => 
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option selected value="">Select District
                                                </Option>
                                                {
                                                    stateCity &&
                                                        stateCity !== undefined || stateCity !== null ?
                                                        stateCity.map((item, i) => (
                                                            <Option key={i} value={item.id}>{item.name}</Option>
                                                        ))
                                                        : "No City"
                                                }
                                            </Select>
                                        </div>

                                        <div className="mb-1  col-md-4">
                                            <label className="form-label">Ward/Kata</label>

                                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}
                                                placeholder="Select minicipality" optionFilterProp="children"  value={minicipalName} 
                                                onChange={(value, option) => { setMinicipal(value); setMinicipalName(value) }}
                                                filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                <Option selected value="">Select/Minicipality
                                                </Option>
                                                {
                                                    stateMinicipality &&
                                                        stateMinicipality !== undefined || stateMinicipality !== null ?
                                                        stateMinicipality.map((item, i) => (
                                                            <Option key={i} value={item.id}>{item.name}</Option>
                                                        ))
                                                        : "No minicipality"
                                                }
                                            </Select>
                                        </div>
                                        <div className="mb-2  col-md-12">
                                            <label className="form-label">Adresse</label>
                                            <textarea className="form-control" placeholder="" id="adresse"
                                                name='adress'
                                                value={adress}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                            ></textarea>
                                        </div>

                                        <div className="form-group col-md-4">
                                            {!Loading && (
                                                <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={() => { UpdateProfile() }}>Update profile</button>
                                            )}

                                            {Loading && (

                                                <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                                </button>
                                            )}
                                        </div>


                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/** end container-fluid */}

        </>
    )
}

export default ProfilSeller
