import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import requestDashBoard from '../../controller/requestDashBoard';
import requestRow from '../../controller/RequestRow';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import { phone } from '../../components/icons';

const Home = () => {
    const data = '';
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [load, setLoad] = useState<requestDashBoard[]>([]);
    const [transactionsCommisions, setTransactionsCommisions] = useState<requestDashBoard[]>([]);
    const [loadPayement, setLoadPayement] = useState<requestRow[]>([]);
    const [soldeCommision, setSoldeCommision] = useState('')
    const [currency, setCurrency] = useState('');
    const [amountVoucher, setAmountVoucher] = useState('');
    const [amountCash, setAmountCash] = useState('');
    const [amountOther, setAmountOther] = useState('');

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
        loadDashboard()
        payementMobile()
    }, []);

    function loadDashboard() {
        api.getHttpRequest(`/dashboard/partner`, data, 'GET').then((response) => {

            const json = response.data;

            setLoad(json.data);
            setTransactionsCommisions(json.data.transactions_commisions)
            setSoldeCommision(json.data.investment_infos.solde_commisions)
            setCurrency(json.data.investment_infos.currency)
            setAmountVoucher(json.data.total_by_method.amount_voucher.montant)
            setAmountCash(json.data.total_by_method.amount_cash.montant)
            setAmountOther(json.data.total_by_method.amount_other.montant)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const payementMobile = () => {
        api.getHttpRequest("/user/payement-method/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoadPayement(json.data.rows);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const getStaus0 = (x: any) => {
        if (x == 1) return ['badge badge-success', 'Success'];
        if (x == 0) return ['badge badge-danger', 'Not success'];
        return '';
    }

    const getStaus1 = (x: any) => {
        if (x == 1) return ['fa fa-circle text-success me-1'];
        if (x == 0) return ['fa fa-circle text-danger me-1'];
        return '';
    }

    let element = loadPayement.find((row: any) => row.status == true && row.is_verified == true);

    const twoColors = { '0%': '#108ee9', '100%': '#87d068' };
    const conicColors = { '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' };

    return (
        <>
            <div className="container-fluid1">

                <div className="row">
                    <div className="col-12">
                        <div>
                            <h2 className="header-title mb-3 font-30 border-none">My Earnings
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        {
                            isMatch ? (<>

                                <div className="card stacked-2">
                                    <div className="card-header flex-wrap border-0 pb-0 align-items-end">
                                        <div className="d-flex align-items-center mb-3 me-3">
                                            <svg className="me-3 mr-4" width="68" height="68" viewBox="0 0 68 68" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                                                    fill="#1EAAE7"></path>
                                            </svg>
                                            <div className="me-auto">
                                                <h5 className="font-20 text-black font-w600">Main Balance</h5>
                                                <span className="text-num text-black font-weight-bold font-30"
                                                    id="main_balance">{currency}.
                                                    {soldeCommision}</span>
                                            </div>
                                        </div>

                                        <div className="me-3 mb-3">
                                            <h5 className="fs-14 mb-1" id="methode_type1">Mobile Money</h5>
                                            <span className="text-black" id="methode_name1">{element && element.MobileOperator ? element.MobileOperator.name : 'Operator'}
                                                {element && element.MobileOperator ? <CheckCircleOutlined style={{ paddingLeft: 5, fontSize: 18, color: '#4CC790' }} /> : ''}
                                            </span>
                                        </div>
                                        <h4 className="font-20" id="methode_adresse1">{element && element.phone ? element.phone : '1'}</h4>

                                    </div>

                                </div>

                            </>) : (
                                <>
                                    <div className="card stacked-2">
                                        <div className="card-header flex-wrap border-0 pb-0 align-items-end">
                                            <div className="d-flex align-items-center mb-3 me-3">
                                                <svg className="me-3 mr-4" width="68" height="68" viewBox="0 0 68 68" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                                                        fill="#1EAAE7"></path>
                                                </svg>
                                                <div className="me-auto">
                                                    <h5 className="font-20 text-black font-w600">Main Balance</h5>
                                                    <span className="text-num text-black font-weight-bold font-30"
                                                        id="main_balance">{currency}.
                                                        {soldeCommision}</span>
                                                </div>
                                            </div>

                                            <div className="me-3 mb-3">
                                                <h5 className="fs-14 mb-1" id="methode_type1">Mobile Money</h5>
                                                <span className="text-black" id="methode_name1">{element && element.MobileOperator ? element.MobileOperator.name : 'Operator'}
                                                    {element && element.MobileOperator ? <CheckCircleOutlined style={{ paddingLeft: 5, fontSize: 18, color: '#4CC790' }} /> : ''}
                                                </span>
                                            </div>
                                            <h4 className="font-20" id="methode_adresse1">{element && element.phone ? element.phone : '+1'}</h4>

                                        </div>

                                    </div>
                                </>
                            )
                        }

                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <div>
                            <div className="card stacked-2 py-2">
                                <div className="row">

                                    {
                                        isMatch ? (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box mb-2 d-flex">

                                                    <Progress className="ml-2 mr-2" type="circle" percent={isNaN((parseInt(amountCash) * 100) / parseInt(soldeCommision)) ? 0 : ((parseInt(amountCash) * 100) / parseInt(soldeCommision))} size={90}
                                                        strokeColor={twoColors}

                                                    />

                                                    <div className="text-center p-1">
                                                        <h2 className="mt-2"><i className="text-warning mdi mdi-cash mr-2"></i>
                                                            <b>{amountCash}</b></h2>
                                                        <p className="text-muted mb-0">Cash</p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box">
                                                    <div className="single-chart">
                                                        <svg viewBox="0 0 36 36" className="circular-chart orange">
                                                            <path className="circle-bg" d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path className="circle" strokeDasharray="30, 100" d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <text x="18" y="20.35" className="percentage">{isNaN((parseInt(amountCash) * 100) / parseInt(soldeCommision)) ? 0 : ((parseInt(amountCash) * 100) / parseInt(soldeCommision))}%</text>
                                                        </svg>
                                                    </div>

                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i className="text-warning mdi mdi-cash mr-2"></i>
                                                            <b>{amountCash}</b></h2>
                                                        <p className="text-muted mb-0">Cash</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        isMatch ? (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box mb-2 d-flex">
                                                    <Progress className="ml-2 mr-2" type="circle" percent={isNaN((parseInt(amountOther) * 100) / parseInt(soldeCommision)) ? 0 : ((parseInt(amountOther) * 100) / parseInt(soldeCommision))} size={90}
                                                        strokeColor={conicColors}

                                                    />

                                                    <div className="text-center p-1">
                                                        <h2 className="mt-2"><i className="text-teal mdi mdi-airplay mr-2"></i>
                                                            <b>{amountOther}</b></h2>
                                                        <p className="text-muted mb-0">Banks, Web & Apps </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box">
                                                    <div className="single-chart">
                                                        <svg viewBox="0 0 36 36" className="circular-chart green">
                                                            <path className="circle-bg" d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path className="circle" strokeDasharray="60, 100" d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <text x="18" y="20.35" className="percentage">{isNaN((parseInt(amountOther) * 100) / parseInt(soldeCommision)) ? 0 : ((parseInt(amountOther) * 100) / parseInt(soldeCommision))}%</text>
                                                        </svg>
                                                    </div>

                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i className="text-teal mdi mdi-airplay mr-2"></i>
                                                            <b>{amountOther}</b></h2>
                                                        <p className="text-muted mb-0">Banks, Web & Apps </p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        isMatch ? (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box d-flex">
                                                    <Progress className="ml-2 mr-2" type="circle" percent={isNaN((parseInt(amountVoucher) * 100 / parseInt(soldeCommision))) ? 0 : ((parseInt(amountVoucher) * 100 / parseInt(soldeCommision)))} size={90}
                                                        strokeColor={twoColors}

                                                    />
                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i className="text-info mdi mdi-ticket mr-2"></i>
                                                            <b>{amountVoucher}</b></h2>
                                                        <p className="text-muted mb-0">Vouchers</p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-xl-3 col-sm-6 widget-inline-box">
                                                    <div className="single-chart">
                                                        <svg viewBox="0 0 36 36" className="circular-chart blue">
                                                            <path className="circle-bg" d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <path className="circle" strokeDasharray="90, 100" d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                            <text x="18" y="20.35" className="percentage">{isNaN((parseInt(amountVoucher) * 100 / parseInt(soldeCommision))) ? 0 : ((parseInt(amountVoucher) * 100 / parseInt(soldeCommision)))}%</text>
                                                        </svg>
                                                    </div>
                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i className="text-info mdi mdi-ticket mr-2"></i>
                                                            <b>{amountVoucher}</b></h2>
                                                        <p className="text-muted mb-0">Vouchers</p>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }


                                    <div className="col-xl-3 col-sm-6">
                                        <div className="text-center p-3">
                                            <div className="card bg-primary mb-0" style={{ cursor: 'pointer' }}
                                                data-toggle="modal" data-target="#exampleModal">
                                                <div className="card-body p-2">
                                                    <div className="d-flex align-items-center">
                                                        <span className="bg-white rounded-circle p-3 mr-4">
                                                            <svg width="28" height="28" viewBox="0 0 28 28"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g opacity="0.98" clipPath="">
                                                                    <path
                                                                        d="M9.77812 2.0125C10.1062 2.69062 9.81641 3.51094 9.13828 3.83906C7.25156 4.74688 5.65469 6.15781 4.51719 7.92422C3.35234 9.73437 2.73438 11.8344 2.73438 14C2.73438 20.2125 7.7875 25.2656 14 25.2656C20.2125 25.2656 25.2656 20.2125 25.2656 14C25.2656 11.8344 24.6477 9.73437 23.4883 7.91875C22.3563 6.15234 20.7539 4.74141 18.8672 3.83359C18.1891 3.50547 17.8992 2.69063 18.2273 2.00703C18.5555 1.32891 19.3703 1.03906 20.0539 1.36719C22.4 2.49375 24.3852 4.24375 25.7906 6.44219C27.2344 8.69531 28 11.3094 28 14C28 17.7406 26.5453 21.257 23.8984 23.8984C21.257 26.5453 17.7406 28 14 28C10.2594 28 6.74297 26.5453 4.10156 23.8984C1.45469 21.2516 1.22342e-07 17.7406 1.66948e-07 14C1.99034e-07 11.3094 0.765625 8.69531 2.21484 6.44219C3.62578 4.24922 5.61094 2.49375 7.95156 1.36719C8.63516 1.04453 9.45 1.3289 9.77812 2.0125Z"
                                                                        fill="#1EAAE7"></path>
                                                                    <path
                                                                        d="M8.67896 13.2726C8.41099 13.0047 8.27974 12.6547 8.27974 12.3047C8.27974 11.9547 8.41099 11.6047 8.67896 11.3367L12.1188 7.89685C12.6219 7.39373 13.2891 7.12029 13.9946 7.12029C14.7 7.12029 15.3727 7.3992 15.8704 7.89685L19.3102 11.3367C19.8461 11.8726 19.8461 12.7367 19.3102 13.2726C18.7743 13.8086 17.9102 13.8086 17.3743 13.2726L15.3563 11.2547L15.3563 19.0258C15.3563 19.7804 14.7438 20.3929 13.9891 20.3929C13.2344 20.3929 12.6219 19.7804 12.6219 19.0258L12.6219 11.2492L10.604 13.2672C10.079 13.8031 9.21489 13.8031 8.67896 13.2726Z"
                                                                        fill="#1EAAE7"></path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip11">
                                                                        <rect width="28" height="28" fill="white"
                                                                            transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 28 28)">
                                                                        </rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span className="font-20 text-white">Withdraw </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to="/add-payment-method"
                                                className="btn btn-outline-primary rounded d-block btn-lg btn-add-payment mt-4"><i className="mdi mdi-plus scale5 font-20"></i> Add
                                                Payout Methods</Link>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex border-0">
                            <div>
                                <h4 className="fs-20 text-black">Transaction History</h4>

                            </div>
                            <div className="card-action card-tabs mt-3 mt-sm-0">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="Monthly_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Monthly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Weekly_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Weekly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Today_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Today</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-0">
                            <div className="tab-pane active show fade" role="tabpanel">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table header-border table-responsive-sm tbl-common">
                                            <thead>
                                                <tr>
                                                    <th>Serial #</th>
                                                    <th>Payment Source</th>
                                                    {/* <th>Dates</th> */}
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>Ref</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tab_">

                                                {
                                                    transactionsCommisions.map((item, i) => (
                                                        <tr key={i} style={{ height: 1 }}>
                                                            <td className="serial"><a href="/" onClick={api.ReturnVoid0}>{item.reference}</a></td>
                                                            <td>{item.methode_payement}</td>
                                                            {/* <td><span className="text-muted">2023-07-11 14:44:46</span></td> */}
                                                            <td>{item.amount}</td>
                                                            <td><span className={getStaus0(item.status)[0]}><i
                                                                className={getStaus1(item.status)[0]}></i>{getStaus0(item.status)[1]}</span>
                                                            </td>
                                                            <td>{item.number_reference}</td>
                                                            <td>{api.geDateFormat(item.createdAt)}</td>
                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <nav>
                                        <ul className="pagination pagination-lg mb-0">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">«</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">»</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div>

            {/** Modal content for the above example */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Withdraw</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2 className="font-24 text-center font-weight-normal">Withdraw to Your Account
                            </h2>
                            <div className="basic-form">
                                <div className="mb-3">
                                    <label className="form-label">&nbsp;</label>
                                    <input type="text" id="montant" className="default-select form-control wide"
                                        placeholder="$0.00" />
                                </div>
                                <div className="d-flex align-items-center p-1 rounded border border-primary">
                                    
                                    <img className="me-3" width="49" height="49" style={{borderRadius:10}} src={element && element.MobileOperator.icon ? element.MobileOperator.icon: `${phone}`} />
                                    <div>
                                        <p className="text-primary fs-14 mb-0 ml-1">Phone number</p>
                                        <span className="text-primary font-w600 ml-1">{element && element.phone ? element.phone : '+1'}</span>
                                    </div>
                                </div>
                                <div className="select-card-wrapper" id="packe"></div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                id="btn-Withdraw">Withdraw</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
