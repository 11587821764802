import React from 'react'
import Header from '../header'
import PageContent from '../PageContent'
import Footer from '../footer'
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../config/Config'
import HeaderVendor from '../header/IndexVendor';

const Container = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <div id="wrapper">
                {
                  api.initRole() === 'Vendor' ? <HeaderVendor /> : <Header />
                  
               }

                {/* <Header /> */}
                <div className="content-page">
                    <div className="content">
                        <PageContent />
                        <Footer />
                    </div>
                </div>
            </div>



        </>
    )
}

export default Container
