import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import api from '../../config/Config'
import requestRow from '../../controller/RequestRow';
import { Pagination, Input } from 'antd';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { default1 } from '../../components/icons';


import {
    SearchOutlined
} from "@ant-design/icons"

const Member = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();
    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [gender, setGender] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');

    const [minicipalName, setMinicipalName] = useState('');
    const [query, setQuery] = useState('')
    const [searchResult, setSearchResult] = useState([]);

    const [countHaveAssurence, setCountHaveAssurence] = useState('');
    const [countNotHaveAssurence, setCountNotHaveAssurence] = useState('');
    const [count, setCount] = useState('');

    // Variables Load elements
    const [province, setPronvince] = useState<requestRow[]>([])
    const [city, setCity] = useState<requestRow[]>([])
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [sellerLoad, setSellerLoad] = useState<requestRow[]>([])
    const [load, setLoad] = useState<requestRow[]>([])

    //Pagination
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
        loadProvince()
        loadCity()
        loadMinicipality()
        loadCategorie_or_Role()
        loadMember(1)
        // Promise.all([loadProvince(), loadCity(), loadMinicipality(), loadCategorie_or_Role(), loadSeller(1)])
        // .catch((error) => {
        //     if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        //         toast.error("Pas de connexion internet");
        //     } else {
        //         toast.error(error.response.data.message);
        //     }
        // });

    }, []);

    //Load seller
    function loadMember(page: any) {
        api.getHttpRequest(`/member/load-member?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoad(json.data.rows);
                // setCountHaveAssurence(json.data.count_have_assurence)
                // setCountNotHaveAssurence(json.data.count_not_have_assurence)
                setCount(json.data.count)
                setTotalPage(json.data.count);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }

        })
    }
    //Add Seller_or_Member
    const Add_Seller_or_Member = async () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("phone", phone);
        formData.append("MunicipalityId", minicipal);
        formData.append("avatar", avatar);
        formData.append("gender", gender);
        formData.append("UserRoleId", userRoleId);
        formData.append("civil_status", civilStatus);
        formData.append("date_of_birth", dateOfBirth);
        formData.append("place_of_birth", placeOfBirth);

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/member/create',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || lastname === '' || phone === ''
            || email === '' || minicipal === '') {
            toast.error('No fields should be empty');
        } else if (!regexTelephone.test(phone)) {
            toast.error('The entry number is incoreact');
        } else if (!regexEmail.test(email)) {
            toast.error('The email address entered is incoreact');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        toast.success(json.message)
                        loadMember(1)
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    // setLoading(false);
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }


    //Load zones
    const loadProvince = () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCity = () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadMinicipality = () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCategorie_or_Role = () => {
        api.getHttpRequest("/parameters/roles/load?groupe=0", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }



    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestRow[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
    }

    const searchSellers = (event: any) => {
        const getSearch = event.target.value;
        if (getSearch.length > 0) {
            const searchdata = sellerLoad.filter((item) => item.name.toLowerCase().includes(getSearch)
                || item.lastname.toLowerCase().includes(getSearch) || item.middlename.toLowerCase().includes(getSearch));
            setSellerLoad(searchdata)
        } else {
            setSellerLoad(searchResult)
        }
        setQuery(getSearch);
    }

    return (
        <>

            <div className="container-fluid1">

                {/** search-bar and active btn */}
                {/* <div className="row pb-5">
                    <div className="col-lg-8">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                <div className="input-group search-area d-lg-inline-flex">
                                    <div className="input-group-append">
                                        <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search here..." value={query} onChange={(e) => searchSellers(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2">
                        <div className="d-flex weather-detail">
                            <span id="invest_status_pan" style={{ backgroundColor: '#1EAAE7', color: 'white' }}><i
                                className="las la-scan"></i> <a id="invest_status">Active</a></span> Status
                        </div>
                    </div>
                </div> */}
                {/** search-bar and active btn */}

                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div>
                                <Toaster
                                    position="bottom-left"
                                    reverseOrder={false}

                                />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <div className="card stacked-2 py-5">
                                            <div className="row">

                                                <div className="col-xl-4 col-sm-6 widget-inline-box">


                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i
                                                            className="text-teal mdi mdi-account-star-outline mr-2"></i>
                                                            <b>{count}</b></h2>
                                                        <p className="text-muted mb-0">Total principal members

                                                        </p>
                                                    </div>
                                                </div>

                                                {/* <div className="col-xl-4 col-sm-6 widget-inline-box">

                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i
                                                            className="text-warning mdi mdi-shield-account-outline mr-2"></i>
                                                            <b>{countHaveAssurence}</b></h2>
                                                        <p className="text-muted mb-0">Members with insurance

                                                        </p>
                                                    </div>
                                                </div> */}

                                                <div className="col-xl-4 col-sm-6 ">

                                                    <div className="text-center p-3">
                                                        <h2 className="mt-2"><i
                                                            className="text-info mdi mdi-account-multiple mr-2"></i>
                                                            <b>{0}</b></h2>
                                                        <p className="text-muted mb-0">Total sub members

                                                        </p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card stacked-2">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card-box border-0 reseller-distributer">
                                            <div className="card-header ">
                                                <div className="left-side">
                                                    <h2 className="m-0 font-24 font-weight-500">All members</h2>
                                                    <p>List of members who have joined by activating voucher
                                                        insurances
                                                    </p>
                                                </div>
                                                <div className="header-left">
                                                    <div className="dashboard_bar" style={{ marginRight: 5 }}>
                                                        <div className="input-group1 search-area1 d-lg-inline-flex" >
                                                            {/* <div className="input-group-append">
                                                                <button className="input-group-text"><i
                                                                    className="mdi mdi-magnify"></i></button>
                                                            </div>
                                                            <input type="text" className="form-control"
                                                                placeholder="Search here..." value={query} onChange={(e) => searchSellers(e)} /> */}
                                                            <Input size="large" placeholder="Searh here..." value={query} onChange={(e) => searchSellers(e)} prefix={<SearchOutlined />} />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                {/* <table
                                                    className="table table-hover mails m-0 table table-actions-bar table-centered">
                                                    <thead>
                                                        <tr>

                                                            <th>No</th>
                                                            <th>FullName</th>
                                                            <th>Phone</th>
                                                            <th>Address</th>
                                                            <th>Form</th>
                                                            <th>Created at</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="cursor-pointer" data-toggle="collapse"
                                                            data-target="#demo5">
                                                            <td>
                                                                1
                                                            </td>

                                                            <td>
                                                                <img src="assets/images/users/avatar-2.jpg"
                                                                    alt="contact-img" title="contact-img"
                                                                    className="rounded-circle avatar-sm mr-2" /> <span>Joshua
                                                                        Mhingo</span>
                                                            </td>

                                                            <td>
                                                                +255757710714
                                                            </td>

                                                            <td>
                                                                Mbezi sala sala
                                                            </td>

                                                            <td>
                                                                <span
                                                                    className="badge badge-pill badge-danger">Used</span>

                                                            </td>
                                                            <td>
                                                                June 17, 2023 at 12:14 PM
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={12}
                                                                className="hiddenRow p-3 mb-2 bg-light text-dark">
                                                                <div className="accordian-body collapse" id="demo5">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr className="info">
                                                                                <th>No</th>
                                                                                <th>FullName</th>
                                                                                <th>Phone</th>
                                                                                <th>Address</th>
                                                                                <th>Created at</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            <tr data-toggle="collapse"
                                                                                className="accordion-toggle"
                                                                                data-target="#demo10">
                                                                                <td>1</td>
                                                                                <td>
                                                                                    <img src="assets/images/users/avatar-2.jpg"
                                                                                        alt="contact-img"
                                                                                        title="contact-img"
                                                                                        className="rounded-circle avatar-sm mr-2" />
                                                                                    <span> Joshua
                                                                                        Mhingo</span>
                                                                                </td>
                                                                                <td>+255757710714</td>
                                                                                <td>24 Katoyi, Avenue KISOKO , Q.
                                                                                    Katoyi, Kirisimbi, Goma RDC</td>
                                                                                <td> 2017/09/27</td>

                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr className="cursor-pointer" data-toggle="collapse"
                                                            data-target="#demo6">
                                                            <td>
                                                                2
                                                            </td>

                                                            <td>
                                                                <img src="assets/images/users/avatar-4.jpg"
                                                                    alt="contact-img" title="contact-img"
                                                                    className="rounded-circle avatar-sm mr-2" /> <span>Tomaslau</span>
                                                            </td>

                                                            <td>
                                                                +255757710714
                                                            </td>

                                                            <td>
                                                                Mbezi sala sala
                                                            </td>

                                                            <td>
                                                                <span
                                                                    className="badge badge-pill badge-success">Pay Insurance</span>

                                                            </td>
                                                            <td>
                                                                June 17, 2023 at 12:14 PM
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={12}
                                                                className="hiddenRow p-3 mb-2 bg-light text-dark">
                                                                <div className="accordian-body collapse" id="demo6">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr className="info">
                                                                                <th>No</th>
                                                                                <th>FullName</th>
                                                                                <th>Phone</th>
                                                                                <th>Address</th>
                                                                                <th>Created at</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>

                                                                            <tr data-toggle="collapse"
                                                                                className="accordion-toggle"
                                                                                data-target="#demo10">
                                                                                <td>1</td>
                                                                                <td>
                                                                                    <img src="assets/images/users/avatar-2.jpg"
                                                                                        alt="contact-img"
                                                                                        title="contact-img"
                                                                                        className="rounded-circle avatar-sm mr-2" />
                                                                                    <span>Joshua
                                                                                        Mhingo</span>
                                                                                </td>
                                                                                <td>+255757710714</td>
                                                                                <td>24 Katoyi, Avenue KISOKO , Q.
                                                                                    Katoyi, Kirisimbi, Goma RDC</td>
                                                                                <td> 2017/09/27</td>

                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}

                                                <table
                                                    // className="table table-hover mails m-0 table table-actions-bar table-centered"
                                                    className="table header-border table-responsive-sm tbl-common">
                                                    <thead>
                                                        <tr>

                                                            <th>No</th>
                                                            <th>FullName</th>
                                                            <th>Gender</th>
                                                            <th>Phone</th>
                                                            <th>Address</th>
                                                            {/* <th>Form</th> */}
                                                            <th>Created at</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            load.map((item, i) => (
                                                                <tr key={i} className="cursor-pointer" data-toggle="collapse"
                                                                    data-target="#demo5">
                                                                    <td>
                                                                        {i + 1}
                                                                    </td>

                                                                    <td>
                                                                        <img src={default1}
                                                                            alt="contact-img" title="contact-img"
                                                                            className="rounded-circle avatar-sm mr-2" />
                                                                        <span>{item.name ? item.name : '' + ' ' + item.middlename ? item.middlename : '' + ' ' + item.lastname ? item.lastname : ''}</span>
                                                                    </td>

                                                                    <td>
                                                                        {item.gender}
                                                                    </td>

                                                                    <td>
                                                                        {item.phone}
                                                                    </td>

                                                                    <td>
                                                                        {item.address}
                                                                    </td>

                                                                    {/* <td>
                                                                        <span
                                                                            className="badge badge-pill badge-danger">Used</span>

                                                                    </td> */}
                                                                    <td>
                                                                        {api.geDateFormat(item.createdAt)}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                style={{
                                                    paddingTop: 10
                                                }}
                                                pageSize={10}
                                                total={totalPage}
                                                onChange={(page: any) => {
                                                    loadMember(page)
                                                }}
                                            />

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/** add new seller Modal */}
            <div className="modal fade" id="add_new_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add or Update reseller
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-info" role="alert">
                                Before creating a reseller account you must make sure you know the person well, especially their
                                real identity, have their photo and physical address and must be able to assume the role of
                                seller of Tiva products.
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <Toaster
                                        position="bottom-left"
                                        reverseOrder={false}

                                    />
                                </div>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="pro-pic " >
                                                    <div className="profile-pic-wrapper">
                                                        <div className="pic-holder mb-0">
                                                            {/** uploaded pic shown here */}
                                                            <img id="profilePic" className="pic" src={default1} />

                                                            <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                                                                onChange={handleImageChange}
                                                            />
                                                            <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                                <div className="text-center">
                                                                    <div className="mb-2">
                                                                        <i className="fa fa-camera fa-2x"></i>
                                                                    </div>
                                                                    <div className="text-uppercase">
                                                                        Update <br /> Profile Photo
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-8 row' >
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='name'
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">LastName</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='lastname'
                                                        value={lastname}
                                                        onChange={(e) => { setLastName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">MiddleName</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='middlename'
                                                        value={middlename}
                                                        onChange={(e) => { setMiddlename(e.target.value); }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Category</label>
                                                    <select className="form-control" id="categorie"
                                                        name='userRoleId'
                                                        value={userRoleId}
                                                        onChange={(e) => { setUserRoleId(e.target.value) }}
                                                    >
                                                        <option value="">Select category</option>
                                                        {
                                                            categoriRole.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }


                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <input type="hidden" id="id" value="00" /> */}


                                    <div className='col-md-12 row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Gender</label>
                                            <select className="form-control" id="gender"
                                                name='gender'
                                                value={gender}
                                                onChange={(e) => { setGender(e.target.value) }}
                                            >
                                                <option>Choose...</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Date of birth</label>
                                            <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                name='dateOfBirth'
                                                value={dateOfBirth}
                                                onChange={(e) => { setDateOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Place of Birth</label>
                                            <input type="text" className="form-control" placeholder="" id="phone"
                                                name='placeOfBirth'
                                                value={placeOfBirth}
                                                onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" placeholder="" id="phone"
                                                name='phone'
                                                value={phone}
                                                onChange={(e) => { setPhone(e.target.value) }}
                                            />
                                        </div>

                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Adress Mail</label>
                                            <input type="email" className="form-control" placeholder="" id="email"
                                                name='email'
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value); }}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">State/Province</label>
                                            <select className="form-control" id="Province" placeholder="Email"
                                                onChange={(e) => GetProvince(e.target.value)}
                                            >
                                                <option value=''>Select State/Province</option>
                                                {
                                                    province &&
                                                        province !== undefined ?
                                                        province.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        ))
                                                        : "No Province"
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3  col-md-6">
                                            <label className="form-label">City</label>
                                            <select className="form-control" id="City" placeholder="Email"
                                                onChange={(e) => GetCity(e.target.value)}
                                            >
                                                <option value=''>Select City</option>
                                                {
                                                    stateCity &&
                                                        stateCity !== undefined || stateCity !== null ?
                                                        stateCity.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                        : "No City"
                                                }

                                            </select>
                                        </div>

                                        <div className="mb-3  col-md-6">
                                            <label className="form-label">Street</label>
                                            <select className="form-control" id="Minicipality" placeholder="Email"
                                                name='minicipality'
                                                value={minicipal}

                                                onChange={(e) => { setMinicipal(e.target.value) }}
                                            >
                                                <option value=''>Select Street</option>
                                                {
                                                    stateMinicipality &&
                                                        stateMinicipality !== undefined || stateMinicipality !== null ?
                                                        stateMinicipality.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                        : "No minicipality"
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3  col-md-12">
                                            <label className="form-label">Adresse</label>
                                            <textarea className="form-control" placeholder="" id="adresse"
                                                name='adress'
                                                value={adress}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                            ></textarea>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">


                            {!Loading && (
                                <button type="button" className="btn btn-primary agree-link font-18"
                                    id="btn-Withdraw"
                                    onClick={() => (Add_Seller_or_Member())}
                                >Save changes</button>
                            )}

                            {Loading && (

                                <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                    id="btn-Withdraw"

                                >
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Member
