import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox,
    Pagination,
    Modal,
    Tabs
} from "antd";
import { useEffect, useState } from "react";
import requestTopay from "../../../controller/requestTopay";

const Pay = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const data = '';
    const [Loading, setLoading] = useState(false);
    const [soldeSendTo, setSoldeSendTo] = useState('');
    const [totalSoldeVouchers, setTotalSoldeVouchers] = useState('');
    const [totalVouchersInStock, setTotalVouchersInStock] = useState('');
    const [countUsed, setCountUsed] = useState('');
    const [numberOfSerieAssigned, setNumberOfSerieAssigned] = useState('');
    const [paymentMethod, setPaymentMethod] = useState<requestTopay[]>([]);
    const [phone, setPhone] = useState('');
    const [operator, setOperator] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [icon, setIcon] = useState('');

    //Payment variables
    const [payId, setPayId] = useState('');
    const [valuePay, setValuePay] = useState('');

    // const [phone_, setPhone] = useState(paymentMethod[0]?.phone);
    // const [operator_, setOperator] = useState(paymentMethod[0]?.MobileOperator.name); 
    // const [selectedItem, setSelectedItem] = useState(paymentMethod[0]);

    // function getId(item: any) {
    //     setActiveIndex(item.id);
    //     setSelectedItem(item);
    //   }
    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        loadToPay();
    }, [])

    function loadToPay() {
        api.getHttpRequest(`/partners/load-to-pay`, data, 'GET').then((response) => {

            const json = response.data;
            setSoldeSendTo(json.data.infos_vendor.solde_send_to);
            setTotalSoldeVouchers(json.data.infos_vendor.total_solde_vouchers);
            setTotalVouchersInStock(json.data.infos_vendor.total_vouchers_in_stock);
            setCountUsed(json.data.infos_vendor.count_used);
            setNumberOfSerieAssigned(json.data.infos_vendor.number_of_serie_assigned)
            setPaymentMethod(json.data.payment_method);
            setPhone(json.data.payment_method[0].phone);
            setOperator(json.data.payment_method[0].MobileOperator.name);
            setIcon(json.data.payment_method[0].MobileOperator.icon);
            setPayId(json.data.payment_method[0].id);
            setValuePay(json.data.infos_vendor.solde_send_to);

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    function getId(element: any) {
        setActiveIndex(element.id);
        setPhone(element.phone)
        setOperator(element.MobileOperator.name)
        setPayId(element.payment_method.id)
        setIcon(element.MobileOperator.icon)
    }

    {/**Button to pay */ }
    const paymentbtn = async () => {
        let data = JSON.stringify({
            "id_methode_payement": payId,
            "amount": valuePay
        });

        setLoading(true);

        api.getHttpRequest("/partners/pay", data, 'POST').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                toast.success(json.message);
                navigate('/payment-history')
                loadToPay()
            } else {
                toast.error(json.message);
            }
        }).catch((error) => {
            // setLoading(false)       
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }


    return (
        <>
            <div className="container-fluid">

                <div className="row ">
                    {
                        isMatch ? (
                            <>
                                <div>
                                    <div className="mb-2 ml-2 d-block d-sm-flex border-0">
                                        <div className="card-action card-tabs  mt-sm-0">
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" id="Weekly_" to="/pay"
                                                        role="tab">Pay</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link " id="Monthly_" to="/vendor"
                                                        role="tab">Home</Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className="nav-link" id="Today_" to="/payment-history"
                                                        role="tab">List of payments</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div>
                                        <h2 className="header-title pb-0 font-30 border-none">Make payment</h2>
                                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                            <label style={{
                                                backgroundColor: 'red',
                                                height: 20,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <p style={{ paddingLeft: 2 }}>Select your payment method</p>
                                        </div>
                                    </div>
                                </div>


                            </>
                        ) : (
                            <>
                                <div className="col-9">
                                    <div>
                                        <h2 className="header-title pb-0 font-30 border-none">Make payment
                                        </h2>
                                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                            <label style={{
                                                backgroundColor: 'red',
                                                height: 20,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <p style={{ paddingLeft: 2 }}>Select your payment method</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="mb-2 ml-5 d-block d-sm-flex border-0">
                                        <div className="card-action card-tabs  mt-sm-0">
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" id="Weekly_" to="/pay"
                                                        role="tab">Pay</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" id="Monthly_" to="/vendor"
                                                        role="tab">Home</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" id="Today_" to="/payment-history"
                                                        role="tab">List of payments</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }


                </div>

                <div className="row">
                    <div className="col-12">
                        <div>
                            <Toaster
                                position="bottom-center"
                                reverseOrder={false}

                            />
                        </div>
                        <Card style={{ width: '100%' }}>
                            {
                                isMatch ? (
                                    <>


                                        {/**Banniers Une */}
                                        <div style={{ display: 'flex', marginBottom: 8 }}>
                                            <label style={{
                                                backgroundColor: '#4920E9',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 0, fontSize: 16 }}>Total serie
                                                </p> Assigned
                                                <strong style={{ marginBottom: 5, fontSize: 16 }}> Tsh: {numberOfSerieAssigned}</strong>

                                            </div>

                                            <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 10, marginLeft: 45 }} />


                                        </div>

                                        <div style={{ display: 'flex' }}>

                                            <div style={{ display: 'flex', marginBottom: 5 }}>
                                                <label style={{
                                                    backgroundColor: '#4920E9',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 1 }}>Total used pins</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 14 }}>Tsh:{countUsed}</strong>
                                                </div>

                                            </div>
                                            <div style={{ display: 'flex', marginLeft: 30 }}>
                                                <label style={{
                                                    backgroundColor: '#4920E9',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Available Pins</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 14 }}>Tsh:{totalVouchersInStock}</strong>
                                                </div>
                                            </div>
                                        </div> <hr />


                                        {/**Bannier deux */}
                                        {/* <div style={{ display: 'flex', marginBottom: 8 }}>
                                            <label style={{
                                                backgroundColor: '#2AB571',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 0, fontSize: 18 }}>Customer Selling Price</p>
                                                <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {0}</strong><br />

                                            </div>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', marginBottom: 5 }}>
                                                <label style={{
                                                    backgroundColor: '#2AB571',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 0 }}>Series Available Amount</p>

                                                    <strong style={{ fontSize: 14 }}>Tsh:{0}</strong><br />



                                                </div>

                                            </div>
                                            <div style={{ display: 'flex', marginLeft: 30 }}>
                                                <label style={{
                                                    backgroundColor: '#2AB571',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 0 }}>Total Amount to Pay / Collect</p>
                                                    <strong style={{ fontSize: 14 }}>Tsh:{0}</strong><br />

                                                </div>

                                            </div>
                                        </div><hr /> */}


                                        {/** Bannires Trois */}
                                        <div style={{ display: 'flex', marginBottom: 15 }}>
                                            <label style={{
                                                backgroundColor: '#2C71BA',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 5, fontSize: 18 }}>Unused Pins Balance</p>
                                                <div style={{ display: 'flex' }}>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {totalSoldeVouchers}</strong>

                                                </div>

                                            </div>

                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <label style={{
                                                backgroundColor: '#0B6AF8',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 5 }}>To Pay
                                                    Subtotal :</p>
                                                <label style={{
                                                    backgroundColor: '#0B6AF8',
                                                    color: '#ffffff', padding: 5, fontSize: 16
                                                }}>Tsh.{soldeSendTo}
                                                </label>

                                            </div>
                                        </div>

                                        <h4 className=" font-18 text-secondary mt-2">Payment mode</h4>
                                        <div className="payment-mode-option">
                                            <ul className="nav nav-pills mb-1 mt-3" id="pills-tab" role="tablist">
                                                {paymentMethod.map((item, index) => (
                                                    <li key={index} className="nav-item">
                                                        <a
                                                            className={`nav-link ${index === activeIndex ? "active" : ""}`}
                                                            id={item.id}
                                                            data-toggle="pill"
                                                            href={`#${item.id}`}
                                                            role="tab"
                                                            aria-controls={item.id}
                                                            aria-selected={paymentMethod[0] ? "true" : "false"}
                                                            onClick={() => getId(item)}
                                                        >
                                                            {item.MobileOperator.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="tab-pane show" id="pills-profile" role="tabpanel" aria-labelledby="m-pesa-01">
                                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tigo-pesa-1">
                                                    <div className="row col-md-12">

                                                        <div className="col-md-6">
                                                            <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                            {/* <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" /> */}
                                                            <div className="d-flex align-items-center p-1 rounded border border-primaryx">
                                                                <img className="me-3" width="49" height="49" style={{ borderRadius: 10 }} src={icon && icon ? icon : `${phone}`} />
                                                                <div>
                                                                    <p className="text-primary fs-14 mb-0 ml-1">{operator}</p>
                                                                    <span className="text-primary font-w600 ml-1">{phone && phone ? phone : '+1'}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 rounded border border-primaryx">
                                                            <label htmlFor="nom" className="form-label ">Amount</label>
                                                            <input type="number" value={valuePay} onChange={(e) => setValuePay(e.target.value)} className="default-select form-control  font-20 text-dark-1 rounded
                                                                                     border border-primaryx" style={{height:55}} placeholder="Amount" />
                                                        </div>

                                                        {!Loading && (
                                                            <button type="button" className="btn btn-primary btn-lg btn-block mt-3" onClick={paymentbtn}>Pay</button>
                                                        )}

                                                        {Loading && (

                                                            <button type="button" className="btn btn-primary btn-lg btn-block mt-3" disabled>
                                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                                            </button>
                                                        )}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* <Row style={{ marginBottom: 15 }}>
                                            <Col span={5} >
                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                    <label style={{
                                                        backgroundColor: '#4920E9',
                                                        height: 50,
                                                        width: 10,
                                                        borderRadius: 10
                                                    }}></label>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ marginBottom: 5 }}>Total Serie Assigned <br /> </p>
                                                        <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {numberOfSerieAssigned}</strong>
                                                    </div>

                                                    <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 4, marginLeft: 15 }} />


                                                </div>
                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                    <label style={{
                                                        backgroundColor: '#4920E9',
                                                        height: 50,
                                                        width: 10,
                                                        borderRadius: 10
                                                    }}></label>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ marginBottom: 5 }}>Total used pins</p>
                                                        <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {countUsed}</strong>
                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <label style={{
                                                        backgroundColor: '#4920E9',
                                                        height: 50,
                                                        width: 10,
                                                        borderRadius: 10
                                                    }}></label>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ marginBottom: 5 }}>Available Pins</p>
                                                        <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {totalVouchersInStock}</strong>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={5} >
                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                    <label style={{
                                                        backgroundColor: '#2C71BA',
                                                        height: 50,
                                                        width: 10,
                                                        borderRadius: 10
                                                    }}></label>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ marginBottom: 5 }}>Unused Pins Balance</p>
                                                        <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {totalSoldeVouchers}</strong><br />

                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    <label style={{
                                                        backgroundColor: '#0B6AF8',
                                                        height: 50,
                                                        width: 10,
                                                        borderRadius: 10
                                                    }}></label>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <p style={{ marginBottom: 5 }}>Balance to send to TIVA:</p>
                                                        <label style={{
                                                            backgroundColor: '#0B6AF8',
                                                            color: '#ffffff', padding: 5, fontSize: 24, borderRadius: 5
                                                        }}>Tsh. {soldeSendTo}
                                                        </label>

                                                    </div>
                                                </div>
                                            </Col>

                                            <Col span={5}>

                                                <h4 className=" font-18 text-secondary">Payment mode</h4>

                                                <div className="payment-mode-option">
                                                    <ul className="nav nav-pills mb-1 mt-3" id="pills-tab" role="tablist">
                                                        {paymentMethod.map((item, index) => (
                                                            <li key={index} className="nav-item">
                                                                <a
                                                                    className={`nav-link ${index === activeIndex ? "active" : ""}`}
                                                                    id={item.id}
                                                                    data-toggle="pill"
                                                                    href={`#${item.id}`}
                                                                    role="tab"
                                                                    aria-controls={item.id}
                                                                    aria-selected={paymentMethod[0] ? "true" : "false"}
                                                                    onClick={() => getId(item)}
                                                                >
                                                                    {item.MobileOperator.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div className="tab-pane show" id="pills-profile" role="tabpanel" aria-labelledby="m-pesa-01">
                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tigo-pesa-1">
                                                            <form action="">
                                                                <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                                <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" />
                                                                <button type="button" className="btn btn-primary btn-lg btn-block mt-3">Pay</button>

                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>

                                        </Row> */}

                                        <div className="table-responsive-sm">
                                            <table className="table table-striped">
                                                <thead className="bg-blue-200 text-black" style={{ color: 'black' }}>

                                                    <tr>
                                                        <th className=" border">Serial Assign</th>
                                                        <th className="right border">Pins</th>
                                                        <th className="center border">Sales Details</th>
                                                        <th className="right border">Total amount to be paid already collected </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="left strong border-right">
                                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                                <label style={{
                                                                    backgroundColor: '#4920E9',
                                                                    height: 30,
                                                                    width: 10,
                                                                    borderRadius: 10
                                                                }}></label>
                                                                <div style={{ marginLeft: 10 }}>
                                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}
                                                                        className="font-16"> {numberOfSerieAssigned}</strong>
                                                                </div>

                                                                <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 4, marginLeft: 15 }} />


                                                            </div>
                                                        </td>
                                                        <td className="right border-right">
                                                            <div className="col-12 p-0 ">
                                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                                    <label style={{
                                                                        backgroundColor: '#4920E9',
                                                                        height: 25,
                                                                        width: 10,
                                                                        borderRadius: 10
                                                                    }}></label>
                                                                    <span className="font-weight-bold ml-1">

                                                                        Assigned:</span> <span className="ml-1"> {countUsed + totalVouchersInStock}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 ">
                                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                                    <label style={{
                                                                        backgroundColor: 'red',
                                                                        height: 25,
                                                                        width: 10,
                                                                        borderRadius: 5
                                                                    }}>

                                                                    </label>
                                                                    <span className="font-weight-bold font-16  ml-1">

                                                                        Used:</span> <span className="ml-1 font-16 ml-2" style={{ color: 'red' }}> {countUsed}</span>

                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 mb-1">
                                                                <div style={{ display: 'flex', marginBottom: 15 }}>
                                                                    <label style={{
                                                                        backgroundColor: 'green',
                                                                        height: 25,
                                                                        width: 10,
                                                                        borderRadius: 5,

                                                                    }}>

                                                                    </label>
                                                                    <span className="font-weight-bold font-16 ml-1">

                                                                        Available:</span> <span className="font-16 ml-2" style={{ color: 'green' }}> {totalVouchersInStock}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="center border-right">
                                                            <div className="col-12 p-0 ">
                                                                <div style={{ display: 'flex', marginBottom: 15 }}>

                                                                    <span className="font-weight-bold ml-1 font-16">

                                                                        Used Pins Balance:</span> <span className="ml-1 font-16">Tsh. {soldeSendTo}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 ">
                                                                <div style={{ display: 'flex', marginBottom: 15 }}>

                                                                    <span className="font-weight-bold ml-1 font-18">

                                                                        Unused Pins Balance:</span> <span className="ml-1 font-16">Tsh. {totalSoldeVouchers}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="center border-right"> <span className="font-24">Tsh. {soldeSendTo}</span></td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6 ml-auto">
                                                <table className="table table-clear">
                                                    <tbody>
                                                        <tr>
                                                            <td className="left">
                                                                <h6 className="mb-0">To Pay</h6>
                                                                <strong className="text-dark-1 font-18">Subtotal</strong>
                                                            </td>
                                                            <td className="right">
                                                                <div style={{ display: 'flex' }}>
                                                                    <div style={{ marginLeft: 10 }}>
                                                                        <label style={{
                                                                            backgroundColor: '#0B6AF8',
                                                                            color: '#ffffff', padding: 5, fontSize: 24, borderRadius: 5
                                                                        }}>Tsh.{soldeSendTo}
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>

                                                        <tr>
                                                            <td className="left">
                                                                <h4 className=" font-18 text-secondary">Payment mode</h4>
                                                                <div className="payment-mode-option">
                                                                    <ul className="nav nav-pills mb-1 mt-3" id="pills-tab" role="tablist">
                                                                        {paymentMethod.map((item, index) => (
                                                                            <li key={index} className="nav-item">
                                                                                <a
                                                                                    className={`nav-link ${index === activeIndex ? "active" : ""}`}
                                                                                    id={item.id}
                                                                                    data-toggle="pill"
                                                                                    href={`#${item.id}`}
                                                                                    role="tab"
                                                                                    aria-controls={item.id}
                                                                                    aria-selected={paymentMethod[0] ? "true" : "false"}
                                                                                    onClick={() => getId(item)}
                                                                                >
                                                                                    {item.MobileOperator.name}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    <div className="tab-pane show" id="pills-profile" role="tabpanel" aria-labelledby="m-pesa-01">
                                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="tigo-pesa-1">
                                                                            {/* <form action="">
                                                                                <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                                                <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" />
                                                                                <button type="button" className="btn btn-primary btn-lg btn-block mt-3">Pay</button>

                                                                            </form> */}
                                                                            <div className="row col-md-12">

                                                                                <div className="col-md-8">
                                                                                    <label htmlFor="nom" className="form-label ">Your Phone Number</label>
                                                                                    {/* <input type="text" value={`${phone}-${operator}`} className="default-select form-control  font-20 text-dark-1" placeholder="Phone nun" /> */}
                                                                                    <div className="d-flex align-items-center p-1 rounded border border-primaryx">

                                                                                        <img className="me-3" width="49" height="49" style={{ borderRadius: 10 }} src={icon && icon ? icon : `${phone}`} />
                                                                                        <div>
                                                                                            <p className="text-primary fs-14 mb-0 ml-1">{operator}</p>
                                                                                            <span className="text-primary font-w600 ml-1">{phone && phone ? phone : '+1'}</span>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-4 " >
                                                                                    <label htmlFor="nom" className="form-label ">Amount</label>
                                                                                    <input type="number" value={valuePay} onChange={(e) => setValuePay(e.target.value)} className="default-select form-control  font-20 text-dark-1 rounded
                                                                                     border border-primaryx" style={{height:55}} placeholder="Amount" />

                                                                                </div>


                                                                                {!Loading && (
                                                                                    <button type="button" className="btn btn-primary btn-lg btn-block mt-3" onClick={paymentbtn}>Pay</button>
                                                                                )}

                                                                                {Loading && (

                                                                                    <button type="button" className="btn btn-primary btn-lg btn-block mt-3" disabled>
                                                                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </>
                                )
                            }


                        </Card>
                    </div>


                </div>

            </div>
        </>
    )
}

export default Pay