import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import requestRow from '../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Badge, Drawer, Input, Pagination } from 'antd';
import { Space, Card, Statistic, Row, Col, Typography } from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined, HddOutlined, FileDoneOutlined,
    SearchOutlined, FileExcelOutlined, MoreOutlined
} from "@ant-design/icons"
import { logoLight } from '../../components/icons';
import RequestStock from '../../controller/RequestStock';
const { Title } = Typography;

const Stock = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = ''

    const [stock, setSock] = useState<RequestStock[]>([])
    const [total, setTotal] = useState('');
    const [totalAsigned, setTotalAsigned] = useState('');
    const [totalInStock, setTotalInStock] = useState('');
    const [totalPage, setTotalPage] = useState(1);

    {/** Loard Views mobile */ }
    const [nameSeler, setNameSeler] = useState('');
    const [selerRole, setSelerRole] = useState('');
    const [reference, setReference] = useState('');
    const [date, setDate] = useState('');
    const [currency, setCurrency] = useState('');
    const [donimination, setDonumination] = useState('');
    const [customerSellingPrice, setCustomerSellingPrice] = useState('');
    const [mobileOperator, setMobileOperator] = useState([]);
    const [used, setUsed] = useState('');
    const [assigned, setAssigned] = useState('');
    const [available, setAvailable] = useState('');
    const [financeUsed, setFinanceUsed] = useState('');
    const [financeSupposedReceived, setFinanceSupposedReceived] = useState('');
    const [paidAmount, setPaidAmount] = useState('');
    const [unpaidAmount, setUnpaidAmount] = useState('');
    const [showSellerPhone, setShowSellerPhone] = useState('');
    const [showId, setShowId] = useState('');
    const [showSellerName, setShowSellerName] = useState('');
    const [sellerIsKyc, setSellerIsKyc] = useState('');
    const [sellerPhone, setSellerPhone] = useState('');
    const [sellerEmail, setSellerEmail] = useState('');
    const [sellerRole, setSellerRole] = useState('');
    const [sellerIsLicence, setSellerIsLicence] = useState('');
    const [placeOfOperation ,setPlaceOfOperation] = useState('');

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }

        loadStock(1)
    }, []);

    function loadStock(page: any) {
        api.getHttpRequest(`/partners/in-yor-stock?page=${page}`, data, 'GET').then((response) => {

            const json = response.data;
            if (json.status == 200) {
                setSock(json.data.data);
                setTotal(json.data.total);
                setTotalAsigned(json.data.total_asigned);
                setTotalInStock(json.data.total_in_stock)
                setTotalPage(json.data.series_number);

            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const getUsed = (x: any) => {
        if (x == true) return ['badge badge-pill bg-danger', 'Used'];
        if (x == false) return ['badge badge-pill bg-success', 'Not used'];
        return '';
    }

    const getTransfer = (x: any) => {
        if (x == true) return ['badge badge-danger', 'Transferred'];
        if (x == false) return ['badge badge-success', 'Not Transferred'];
        return '';
    }

    function geDateFormat(dateString: string): string {
        const date = new Date(Date.parse(dateString));
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }
    // function loadSeller(page: any) {
    //     api.getHttpRequest(`/member/load?page=${page}&size=2`, data, 'GET').then((response) => {
    //         const json = response.data;
    //         if (json.status == 200) {
    //             setSellerLoad(json.data.rows);
    //             setSearchResult(json.data.rows);
    //             setTotalPage(json.data.count);
    //         }
    //     }).catch((error) => {
    //         if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //             toast.error("Please connect your device to the internet");
    //         } else if (error.response && error.response.data && error.response.data.message) {
    //             toast.error(error.response.data.message);
    //         } else {
    //             toast.error(error.message);
    //         }
    //     })
    // }


    //Show Dwower
    const [openDawer, setOpenDrawer] = useState(false);

    const showDrawer = (element: any) => {
        setOpenDrawer(true)
        setNameSeler(element.provider_name)
        // setSelerRole(element.seller_role)
        setReference(element.series)
        setDate(element.date)
        setCurrency(element.infos.currency)
        setDonumination(element.infos.status_available)
        setCustomerSellingPrice(element.infos.amount_selling_prince)
        setMobileOperator(element.mobile_operator)
        setUsed(element.infos.status_used)
        setAssigned(element.infos.status_asigned)
        setAvailable(element.infos.available_pins)
        setFinanceUsed(element.infos.series_available_amount)
        setPlaceOfOperation(element.place_of_operation_at_tiva)
        // setFinanceSupposedReceived(element.finance_supposed_received)
        // setPaidAmount(element.paid_amount)
        // setUnpaidAmount(element.unpaid_amount)
        // setShowSellerPhone(element.seller_phone)
        // setShowId(element.id)
        setShowSellerName(element.seller_name)
        // setSellerIsKyc(element.seller_is_Kyc)
        setSellerPhone(element.provider_phone)
        setSellerEmail(element.provider_email)
        // setSellerRole(element.seller_role)
        // setSellerIsLicence(element.seller_is_Licence)

    }
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    return (
        <>
            <div className="container-fluid1">

                <div className="row">
                    <div className="col-12">
                        <div>
                            <h2 className="header-title pb-0 font-30 border-none">In your Office Stock
                            </h2>
                            <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 10 }}>
                                <label style={{
                                    backgroundColor: 'red',
                                    height: 20,
                                    width: 6,
                                    borderRadius: 10
                                }}></label>
                                <p style={{ paddingLeft: 2 }}>Vouchers that are in your office</p>
                            </div>
                        </div>
                    </div>
                </div>


                {/** end row */}



                <div className="row">
                    <div className="col-12">
                        <div>
                            <Space direction="horizontal" >

                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <TransactionCard icon={<HddOutlined
                                            style={{
                                                color: "#1576ED",
                                                backgroundColor: "White",
                                                borderRadius: 20,
                                                padding: 12,
                                                fontSize: 18,
                                                boxShadow: '30px 30px 30px 30px #f5f5f5'
                                            }}
                                        />}
                                            title={"Total Pins"} value={total} />
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <TransactionCard icon={<HddOutlined
                                            style={{
                                                color: "#1576ED",
                                                backgroundColor: "White",
                                                borderRadius: 20,
                                                padding: 12,
                                                fontSize: 18,
                                                boxShadow: '30px 30px 30px 30px #f5f5f5'
                                            }}
                                        />}
                                            title={"Total pins assigned "} value={totalAsigned} />
                                    </Col>

                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <TransactionCard icon={<HddOutlined
                                            style={{
                                                color: "#1576ED",
                                                backgroundColor: "White",
                                                borderRadius: 20,
                                                padding: 12,
                                                fontSize: 18,
                                                boxShadow: '30px 30px 30px 30px #f5f5f5'
                                            }}
                                        />}
                                            title={"Total pins in Stock "} value={totalInStock} />
                                    </Col>
                                </Row>
                            </Space>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/** end row */}

                {
                    isMatch ? (<></>) :
                        (<>
                            <div className="mt-2">

                                <Card style={{ width: '100%' }}>

                                    {
                                        isMatch ? (
                                            <table style={{
                                                border: '1px solid black',
                                                width: '100%',
                                                borderColor: '#F2F2F2',
                                                display: 'row'
                                            }}>

                                                <tbody >
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Title" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Denomination" />
                                                            </div>
                                                        </td >
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Selling Price" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Operrator</option>
                                                                </select>
                                                            </div>

                                                        </td>
                                                    </tr>

                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Category</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Currency</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <button className="w-100" style={{
                                                                    background: '#4691EE', borderColor: '#4691EE', color: 'white',
                                                                    padding: 2
                                                                }}><SearchOutlined />Filter</button>
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <button className="w-100" style={{
                                                                    background: '#39A945', color: 'white', borderColor: '#39A945',
                                                                    padding: 2
                                                                }}><FileExcelOutlined />Export</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        ) : (
                                            <table style={{
                                                border: '1px solid black',
                                                width: '100%',
                                                borderColor: '#F2F2F2'
                                            }}>
                                                <tbody >
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Title" />
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Denomination" />
                                                            </div>
                                                        </td >
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="text" className="form-control" placeholder="Selling Price" />
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Operrator</option>
                                                                </select>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: '1px solid #F0F0F0' }}>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Category</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <select className="form-control">
                                                                    <option value="">All Currency</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <input type="date" className="form-control" />
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <button className="w-100" style={{
                                                                    background: '#4691EE', borderColor: '#4691EE', color: 'white',
                                                                    padding: 2
                                                                }}><SearchOutlined />Filter</button>
                                                            </div>
                                                        </td>
                                                        <td style={{ border: '1px solid #F0F0F0' }}>
                                                            <div className="inputBox m-1">
                                                                <button className="w-100" style={{
                                                                    background: '#39A945', color: 'white', borderColor: '#39A945',
                                                                    padding: 2
                                                                }}><FileExcelOutlined />Export</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    }


                                </Card>

                            </div >
                        </>)
                }


                {/** table */}
                {
                    isMatch ? (
                        <>
                            <div className="mt-2">

                                <Card style={{ width: '100%' }}>
                                    <Input style={{ color: 'black' }} size="large" placeholder="Searh here..." prefix={<SearchOutlined />} />
                                    <span className="fs-12" style={{ paddingTop: 30, paddingBottom: 30 }}>List vouhers assigned</span>
                                    <div style={{ width: '100%' }}>
                                        {
                                            stock.map((item, i) => (
                                                <div key={i} className="d-flex pb-3 border-bottom mb-3 ">
                                                    <div className="">
                                                        <div className='col-12'>
                                                            <div className='row'>

                                                                <div className='col-10'>
                                                                    <div style={{ display: 'flex' }}>

                                                                        <div >
                                                                            <img src={logoLight} alt="" className="rounded-circle mr-1" width="80" /><br />
                                                                            <strong style={{ color: '#68C3EE' }}>{item.series}</strong>
                                                                        </div>


                                                                    </div>
                                                                    <h6 className="fs-16 font-w600 mb-0 " >
                                                                        <span>Names:{item.provider_name}</span>

                                                                    </h6>
                                                                    <span className="fs-12"> Date : {api.geDateFormat(item.date)} <br />
                                                                        <div style={{
                                                                            backgroundColor: '#D9DCE1', paddingLeft: 20, paddingRight: 20,
                                                                            paddingTop: 2, paddingBottom: 2, fontSize: 16, marginLeft: 10, marginTop: 5
                                                                        }} onClick={() => showDrawer(item)}>Views Details</div> </span>
                                                                </div>

                                                                <div className='col-2' style={{ width: 100 }}>
                                                                    <div className="dropdown" >
                                                                        <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                            <MoreOutlined style={{ fontSize: 30 }} />
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-right" >
                                                                            <Link className="dropdown-item" to="">More</Link>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            ))
                                        }
                                    </div>
                                    <Pagination
                                        style={{
                                            paddingTop: 0,
                                            paddingBottom: 10
                                        }}
                                        pageSize={10}
                                        total={totalPage}
                                        onChange={(page: any) => {
                                            // loadSeller(page)
                                        }}
                                    />
                                </Card>


                            </div>
                        </>
                    ) :
                        (
                            <>
                                < div className="col-xl-12" >
                                    <div className="card row mt-3 ">

                                        <div className="card-body payment-bx tab-content p-0">
                                            <div className="tab-pane active show fade" role="tabpanel">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table header-border table-responsive-sm tbl-common">


                                                            <thead style={{
                                                                height: 5
                                                                // borderBottom: '1px 1px solid #E3E3E3 !important'
                                                            }}>

                                                                <tr style={{ height: 5 }}>
                                                                    {/* <th style={{ width: 40, paddingLeft: 2, }}>#</th> */}
                                                                    <th style={{ borderLeft: '2px solid #ffffff', }}>Inventory# </th>
                                                                    <th style={{ borderLeft: '2px solid #ffffff' }}>Total vouchers</th>
                                                                    <th style={{ borderLeft: '2px solid #ffffff' }}>Operators</th>
                                                                    <th style={{ borderLeft: '2px solid #ffffff' }}>Status</th>
                                                                    <th style={{ borderLeft: '2px solid #ffffff' }}>Finance</th>
                                                                    <th colSpan={2} style={{ borderLeft: '2px solid #ffffff' }}>Provider</th>
                                                                    {/* <th style={{ borderLeft: '2px solid #ffffff' }}>More</th> */}

                                                                </tr>
                                                            </thead>

                                                            <tbody id="tab_">
                                                                {
                                                                    stock.map((item, i) => (
                                                                        <tr key={i} style={{ borderBottom: '1px solid #E8E8E8' }}>
                                                                            {/* <td scope="row" style={{ width: 10, paddingLeft: 2, paddingRight: 8, color: "black", fontSize: 20 }}>
                                                        1
                                                    </td> */}
                                                                            <td >
                                                                                <img src={logoLight} style={{ width: 100, }} /><br />
                                                                                <strong style={{ color: '#68C3EE' }}>{item.series}</strong><br /><br />
                                                                                <strong style={{ paddingTop: 50 }}>{api.geDateFormat(item.date)}</strong>
                                                                            </td>
                                                                            <td  >
                                                                                <div>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Currency:</strong> {item.infos.currency}.{item.infos.amount_selling_prince}</p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Donimination:</strong><br />{item.infos.status_available} </p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Customer Selling price:</strong><br /> {item.infos.currency}.{item.infos.amount_selling_prince}</p>
                                                                                    <p><strong style={{ fontSize: 16 }}>Inventory Order Total:</strong><br /> {item.infos.currency}.{item.infos.series_available_amount}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td >
                                                                                <div>
                                                                                    {/* <p>{item.mobile_operator.join(', ')}</p> */}
                                                                                    {item.mobile_operator.map((operator) => (
                                                                                        <p key={operator} style={{ marginBottom: '10px' }}>
                                                                                            {operator}
                                                                                        </p>
                                                                                    ))}
                                                                                </div>
                                                                            </td>
                                                                            <td  >
                                                                                <div >
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Used:</strong> {item.infos.status_used}</p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Asigned:</strong><br /> {item.infos.status_asigned}</p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Available:</strong><br /> {item.infos.available_pins}</p>
                                                                                </div>

                                                                            </td>
                                                                            <td>
                                                                                {item.infos.currency}.{item.infos.series_available_amount}

                                                                            </td>
                                                                            <td style={{ maxWidth: '230px' }}>
                                                                                <div>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Names:</strong> {item.provider_name}</p>

                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Place of operation at Tiva:</strong> <br />
                                                                                        {item.place_of_operation_at_tiva}
                                                                                    </p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Phone:</strong> {item.provider_phone}</p>
                                                                                    <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Email:</strong> {item.provider_email}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td>

                                                                            </td>
                                                                            {/* <td>
                                                                <button style={{
                                                                    marginTop: 65,
                                                                    backgroundColor: '#F3F6F9',
                                                                    color: 'black', borderColor: '#F3F6F9',
                                                                    borderRadius: 5, height: 30, paddingBottom: 5,
                                                                    width: 80

                                                                }}>More</button>
                                                            </td> */}
                                                                        </tr>
                                                                    ))
                                                                }


                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <nav>
                                                        <div className="pagination pagination-lg mb-0">
                                                            <Pagination
                                                                style={{
                                                                    paddingTop: 10

                                                                }}
                                                                responsive
                                                                pageSizeOptions={[10, 20, 30, 40]}
                                                                pageSize={10}
                                                                total={totalPage}
                                                                onChange={(page: any) => {
                                                                    loadStock(page)
                                                                }}
                                                            />
                                                        </div>
                                                    </nav>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div >
                            </>
                        )
                }

                {/** table */}



            </div >

            <Drawer
                title={`Distrubition Stock`}
                placement={'bottom'}
                width={500}
                height={500}
                onClose={closeDrawer}
                open={openDawer}

            >
                <div style={{ display: 'flex', marginTop: 0 }}>
                    <label style={{
                        backgroundColor: 'red',
                        height: 20,
                        width: 8,
                        borderRadius: 10
                    }}></label>
                    <div style={{ marginLeft: 2 }}>
                        <h5 style={{ color: 'black' }}>Inventory#</h5>
                    </div>

                </div>

                <div style={{ display: 'flex', marginTop: 0 }}>
                    <div style={{ marginLeft: 1 }}>

                        <img src={logoLight} style={{ width: 100, }} /><br />
                        <strong style={{ color: '#68C3EE' }}>{reference}</strong><br />
                        <strong >{api.geDateFormat(date)}</strong>
                    </div>

                </div><hr />

                <div style={{ display: 'flex', }}>
                    <div style={{ marginLeft: 1, marginRight: 40 }}>
                        <h6 style={{ color: 'black' }}>* Total vouchers</h6>
                        <p ><strong >Currency:</strong> {currency}</p>
                        <p ><strong >Donimination:</strong>{donimination} </p>
                        <p ><strong >Customer selling price:</strong> {customerSellingPrice}</p>
                        <p><strong >Inventory order total:</strong> {parseInt(donimination) * parseInt(customerSellingPrice)}</p>
                    </div>
                    <label style={{
                        backgroundColor: 'red',
                        height: 110,
                        width: 4,
                        borderRadius: 10
                    }}></label>
                    <div style={{ marginRight: 0, marginLeft: 2 }}>
                        <h6 style={{ color: 'red' }}>Operators</h6>
                        {mobileOperator.map((operator) => (
                            <p key={operator} style={{ marginBottom: '10px' }}>
                                {operator}
                            </p>
                        ))}
                    </div>
                </div><hr />

                {/* <div style={{ display: 'flex', }}>
                    <div style={{ marginLeft: 1, marginRight: 15 }}>
                        <h6 style={{ color: 'red' }}>* Status</h6>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Used:</strong> {used}</p>

                    </div>

                    <div style={{ marginLeft: 1, marginRight: 15 }}>
                        <h6 style={{ color: 'white' }}>* Status</h6>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Asigned:</strong> {assigned}</p>
                    </div>
                    <div style={{ marginLeft: 1, }}>
                        <h6 style={{ color: 'white' }}>* Status</h6>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Available:</strong> {available}</p>
                    </div>


                </div><hr /> */}

                <div style={{ display: 'flex', }}>
                    <div style={{ marginRight: 40 }}>
                        <h6 style={{ color: 'red', fontSize:18 }}>* Status</h6>
                        <p ><strong >Used:</strong> {used}</p>
                        <p ><strong >Asigned:</strong> {assigned}</p>
                        <p ><strong >Available:</strong> {available}</p>
                    </div>
                    
                    <div>
                        <h6 style={{ color: 'black', fontSize:18 }}>* Financy</h6>
                        <p style={{ marginBottom: 1 , color:'black', fontSize:18}}>{currency}.{financeUsed}</p>
                    </div>
                </div><hr />

                <div style={{ display: 'flex' }}>
                    <div>
                        <h6 style={{ color: 'black', }}>* Distribution channel</h6>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Seller:</strong>
                            <strong style={{ marginBottom: 5, fontSize: 16, color: 'black' }}> {nameSeler}</strong></p>

                        {/* <button style={VerifyKEYC(sellerIsKyc)}>{parseInt(sellerIsKyc) == 1 ? 'KYC Ok' : 'KYC No'}</button> */}
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Phone:</strong> {sellerPhone}
                        </p>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Email:</strong> {sellerEmail}</p>
                        <p style={{ marginBottom: 1 }}><strong style={{ fontSize: 16 }}>Place of operation at Tiva:</strong>{placeOfOperation}</p>
                        {/* <button style={VerifyLicence(sellerIsLicence)}>{parseInt(sellerIsLicence) == 1 ? 'License: Ok' : 'License: No'}</button> */}

                    </div>
                </div>
            </Drawer>

        </>
    )
}

function TransactionCard({ title, value, icon }: any) {
    return (

        <Card
            hoverable
            style={{ width: 330, boxSizing: 'border-box', padding: 10 }}
            bodyStyle={{ display: 'table', width: '100%' }}

        >
            <Space >

                <Statistic style={{ color: "black" }} value={value}
                    valueStyle={{ color: 'black', fontSize: '26px' }}
                    title={<Title level={3} style={{ fontSize: '16px' }}>{title}</Title>}
                />

                {icon}

            </Space>

        </Card>
    )
}

export default Stock
