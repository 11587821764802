import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox,
    Pagination,
    Modal,
    Tabs
} from "antd";

import {
    SearchOutlined, FileExcelOutlined, MoreOutlined
} from "@ant-design/icons"
import { default1, logoLight, logoSm, phone1 } from '../../../components/icons';
import requestRow from '../../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ReactToPrint from 'react-to-print'

type MyCustomCSS = React.CSSProperties & {
    textAlign?: string;
};

const Data = {
    count_used: Number,
    deposit_amount: Number,
    reste: Number,
    amount: Number,
};


const BackToCollect = () => {

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();
    const data = '';
    const [transaction, setTransaction] = useState<requestRow[]>([])
    const [LoadInventory, setLoadInventory] = useState<requestRow[]>([])
    const [load, setLoad] = useState<requestRow[]>([])
    const [totalSeriesFromSellersAmount, setTotalSeriesFromSellersAmount] = useState('')
    const [seriesUsedTotal, setSeriesUsedTotal] = useState('')
    const [upaidPayOnNextOrder, setUpaidPayOnNextOrder] = useState('')
    const [surplierPaidInvoices, setSurplierPaidInvoices] = useState('')
    const [suplierUnpaidInvoices, setSuplierUnpaidInvoices] = useState('')
    const [supplierUnpaidOnNextOder, setSupplierUnpaidOnNextOder] = useState('')
    const [countInvoicePaid, setCountInvoicePaid] = useState('')
    const [countInvoiceUnpaid, setCountInvoiceUnpaid] = useState('')
    const [paidOnNetOrderAmount, setPaidOnNetOrderAmount] = useState('')
    const [myEarnings, setMyEarnings] = useState('')
    const [pendingWithdraws, setPendingWithdraws] = useState('')
    const [paidOut, setPaidOut] = useState('')

    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [middleName, setMiddlename] = useState('')

    const [totalPage, setTotalPage] = useState(1);
    console.log(LoadInventory)
    //Set request
    const [phone, setPhone] = useState('');
    const [id, setId] = useState('');
    const [nameSeller, steNameSeller] = useState('');

    const [sommePins, setSommesPins] = useState('')
    const [sommeTotal, setSommeTotal] = useState('')
    const [sommeAvailablePins, setSommeAvailablePins] = useState('')
    const [sommeDiscount, setSommeDiscount] = useState('')
    const [sommeVatAmount, setSommeVatAmount] = useState('')

    const componentRef = useRef(null);

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
        loadInventory(1)
        LoadConstant()
    }, []);

    function loadInventory(page: any) {
        api.getHttpRequest(`/partners/transactions?page=${page}`, data, 'GET').then((response) => {

            const json = response.data;
            if (json.status == 200) {
                setTransaction(json.data.results);
                setTotalSeriesFromSellersAmount(json.data.total_series_from_sellers_amount)
                setSeriesUsedTotal(json.data.series_used_total)
                setUpaidPayOnNextOrder(json.data.upaid_pay_on_next_order)
                setSurplierPaidInvoices(json.data.surplier_paid_invoices)
                setSuplierUnpaidInvoices(json.data.suplier_unpaid_invoices)
                setSupplierUnpaidOnNextOder(json.data.supplier_unpaid_on_next_oder)
                setCountInvoicePaid(json.data.count_invoice_paid)
                setCountInvoiceUnpaid(json.data.count_invoice_unpaid)
                setPaidOnNetOrderAmount(json.data.paid_on_net_order_amount)
                setMyEarnings(json.data.my_earnings)
                setPendingWithdraws(json.data.pending_withdraws)
                setPaidOut(json.data.paid_out)

                setTotalPage(json.count)

            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadConstant = async () => {
        api.getHttpRequest("/parameters/constante/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoad(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const resultTva = load.find(({ name }: any) => name === 'Tva')?.value ?? "0";
    const resultDiscount = load.find(({ name }: any) => name === 'Discount')?.value ?? "0";

    // Créer une fonction qui retourne le bouton selon les données
    const getButton = (count_used: any, deposit_amount: any, reste: any, amount: any, onClick: () => void) => {

        // Définir les styles communs
        const buttonStyle: MyCustomCSS = {
            height: 25,
            padding: 0,
            width: 80,
            textAlign: 'center',
        };

        // Vérifier les conditions et retourner le bouton correspondant
        if (amount === reste) {
            return (
                <Button style={{ ...buttonStyle }} onClick={onClick}>
                    Request
                </Button>
            );
        } else if (count_used === deposit_amount) {
            <Button style={{ ...buttonStyle }}>
                PAID
            </Button>
        }
        else if (count_used !== deposit_amount) {
            if (count_used === reste) {
                return (
                    <Badge count={5} size="small" style={{ backgroundColor: 'red', marginRight: 10 }}>
                        <Button style={{ ...buttonStyle, backgroundColor: 'red', borderColor: 'red', color: '#ffffff' }} onClick={onClick}>
                            Request
                        </Button>
                    </Badge>
                );
            } else {
                return (
                    <Button style={{ ...buttonStyle }}>
                        Request
                    </Button>
                );
            }
        } else if (amount === count_used && amount === deposit_amount) {
            return (
                <Button style={{ ...buttonStyle, backgroundColor: '#39A945', borderColor: '#39A945', color: '#ffffff' }} onClick={onClick}>
                    Collected
                </Button>
            );
        }
    };


    //Modal Request
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [toprint, setToprint] = useState(false);

    function showModal(phone: any, id_: any, name: string) {
        setOpen(true);
        setPhone(phone);
        setId(id_);
        steNameSeller(name);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    function showPrint(id: any, element: any) {
        setToprint(true);
        setName(element.name);
        setMiddlename(element.middlename);
        setRole(element.role);
        setPhone(element.phone);
        api.getHttpRequest(`/partners/transactions-eventory/${id}`, data, 'GET').then((response) => {

            const json = response.data;
            if (json.status == 200) {
                setLoadInventory(json.data.data);
                setSommesPins(json.data.somme_pins);
                setSommeTotal(json.data.somme_total);
                setSommeAvailablePins(json.data.somme_available_pins);
                setSommeDiscount(json.data.somme_discount);
                setSommeVatAmount(json.data.somme_vat_amount)
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    const handleCancelPrint = () => {
        setToprint(false);
    }
    return (
        <>

            <div className="row mb-3">
                <div className="col-lg-8">
                    <div style={{ display: 'flex' }}>
                        <h6 style={{ marginRight: 20, cursor: "pointer" }}>Back to collect</h6>
                    </div>
                    <p>List of menmbers who have joined by activating voucher insurances</p>
                </div>

                <div className="col-lg-2"></div>
                <div className="col-lg-2">
                    <Input size="large" placeholder="Searh here..." prefix={<SearchOutlined />} />
                </div>

            </div>

            {
                isMatch ? (<></>) :
                    (<>
                        <table style={{
                            border: '1px solid black',
                            width: '100%',
                            borderColor: '#F2F2F2'
                        }}>
                            {
                                isMatch ? (
                                    <>
                                        <tbody style={{ display: 'row' }}>
                                            <tr style={{ border: '1px solid #F0F0F0', display: 'row', }} >
                                                <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <input type="text" className="form-control" placeholder="Operator name" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select Service</option>
                                                        </select>
                                                    </div>
                                                </td >
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select Status</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0', display: 'row' }}>
                                                <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select country</option>
                                                        </select>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0' }}>
                                                <td colSpan={2} style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Call from Api</option>
                                                        </select>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0' }}>
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <Button
                                                            style={{
                                                                background: '#4691EE', color: 'white', borderColor: '#4691EE',
                                                                marginRight: 5, width: 130
                                                            }}
                                                            icon={<SearchOutlined />}>
                                                            Filter
                                                        </Button>

                                                        <Button style={{
                                                            background: '#39A945', color: 'white', borderColor: '#39A945',
                                                            width: 130
                                                        }} icon={<FileExcelOutlined />}>Export</Button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </>
                                ) : (
                                    <>
                                        <tbody >
                                            <tr style={{ border: '1px solid #F0F0F0' }}>
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <input type="text" className="form-control" placeholder="Operator name" />
                                                    </div>
                                                </td>
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select Service</option>
                                                        </select>
                                                    </div>
                                                </td >
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select Status</option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Select country</option>
                                                        </select>
                                                    </div>

                                                </td>
                                            </tr>
                                            <tr style={{ border: '1px solid #F0F0F0' }}>
                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <select className="form-control">
                                                            <option value="">Call from Api</option>
                                                        </select>
                                                    </div>
                                                </td>

                                                <td style={{ border: '1px solid #F0F0F0' }}>
                                                    <div className="inputBox m-1">
                                                        <Button
                                                            style={{
                                                                background: '#4691EE', color: 'white', borderColor: '#39A945',
                                                                marginRight: 5
                                                            }}
                                                            icon={<SearchOutlined />}>
                                                            Filter
                                                        </Button>

                                                        <Button style={{
                                                            background: '#39A945', color: 'white', borderColor: '#39A945',

                                                        }} icon={<FileExcelOutlined />}>Export</Button>
                                                    </div>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </>
                                )
                            }

                        </table>

                        <div className='mt-5'>
                            {
                                isMatch ? (
                                    <>


                                        <div style={{ display: 'flex' }}>
                                            <label style={{ marginTop: 10, marginRight: 5 }}>From</label>
                                            <div className="inputBox m-1">
                                                <input style={{ width: 237 }} type="date" className="form-control" placeholder="Operator name" />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', }}>
                                            <label style={{ marginTop: 10, marginRight: 5 }}>To</label>
                                            <div className="inputBox m-1">
                                                <input style={{ marginLeft: 18, width: 237 }} type="date" className="form-control" placeholder="Operator name" />
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div className="inputBox m-1">
                                                    <select style={{ width: 135 }} className="form-control">
                                                        <option value="">Status</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <label style={{ marginRight: 2, marginLeft: 10, color: '#2C71BA', cursor: 'pointer' }}>Reset Filters</label>

                                            <button style={{
                                                backgroundColor: '#4691EE',
                                                color: '#ffffff', borderRadius: 5, borderColor: '#4691EE',
                                                height: 40, width: 100, marginLeft: 10
                                            }}><SearchOutlined style={{ fontSize: 30 }} /></button>
                                        </div>


                                    </>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex', marginRight: 10 }}>
                                            <div style={{ display: 'flex' }}>
                                                <label style={{ marginTop: 10, marginRight: 5 }}>From</label>
                                                <div className="inputBox m-1">
                                                    <input type="date" className="form-control" placeholder="Operator name" />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', marginLeft: 10 }}>
                                                <label style={{ marginTop: 10, marginRight: 5 }}>To</label>
                                                <div className="inputBox m-1">
                                                    <input type="date" className="form-control" placeholder="Operator name" />
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', marginLeft: 10 }}>
                                                <div className="inputBox m-1">
                                                    <select className="form-control">
                                                        <option value="">Status</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <label style={{ marginTop: 10, marginRight: 5, marginLeft: 10, color: '#2C71BA', cursor: 'pointer' }}>Reset Filters</label>

                                            <button style={{
                                                backgroundColor: '#4691EE',
                                                color: '#ffffff', borderRadius: 5, borderColor: '#4691EE',
                                               marginTop: 10, marginRight: 5, marginLeft: 10
                                            }}>Search</button>

                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </>)
            }



            <div className='mt-4'>
                {
                    isMatch ? (
                        <>
                            {
                                transaction.map((item, i) => (
                                    <div key={i} className="d-flex pb-3 border-bottom mb-3 align-items-center">
                                        <img src={default1} alt="" className="rounded-circle mr-1" width="40" />
                                        <div className="me-auto">
                                            <div className='col-12'>
                                                <div className='row'>

                                                    <div className='col-10'>
                                                        <h6 className="fs-16 font-w600 mb-0 " >
                                                            <span >{item.name + ' ' + item.middlename}</span>

                                                        </h6>
                                                        <span className="fs-12">{item.gender} | Type : {item.role} <br />Tel: {item.phone} </span>
                                                    </div>

                                                    <div className='col-2' style={{ width: 100 }}>
                                                        <div className="dropdown" >
                                                            <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                <MoreOutlined style={{ fontSize: 30 }} />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right" >

                                                                <div className="dropdown-item">
                                                                    <Button
                                                                        style={{
                                                                            height: 30,
                                                                            padding: 0,
                                                                            width: 130
                                                                        }}
                                                                        onClick={() => showPrint(item.userPartnerId, item)}
                                                                    >
                                                                        Print
                                                                    </Button>
                                                                </div>
                                                                <div className="dropdown-item">
                                                                    {getButton(`${item.count_used}`, `${item.deposit_amount}`, `${item.reste}`, `${item.amount}`, () => showModal(`${item.phone}`, `${item.id}`, `${item.name}`))}
                                                                </div>

                                                                <div className="dropdown-item">
                                                                    <Button
                                                                        style={{
                                                                            height: 30,
                                                                            padding: 0,
                                                                            width: 130
                                                                        }}
                                                                        onClick={() => navigate(`/seller_account/${item.userPartnerId}`)}
                                                                    >
                                                                        More
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                ))
                            }
                            <Pagination
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 10
                                }}
                                pageSize={10}
                                total={totalPage}
                                onChange={(page: any) => {
                                    loadInventory(page)
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className='table header-border table-responsive-sm tbl-common'>
                                    <thead style={{
                                        height: 5
                                    }}>
                                        <tr style={{ height: 5 }}>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Partener </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Type </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Stock order #</th>

                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Invoice Date </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Total Series <br />Amount ordered </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Series Used <Badge count={5} size="small" style={{ backgroundColor: 'red', marginLeft: 10, cursor: 'pointer' }}></Badge> </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Paid </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Unpaid </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Receipt </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>Status </th>
                                            <th style={{ borderLeft: '2px solid #ffffff', }}>More </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {
                                            transaction.map((item, i) => (
                                                <tr key={i} style={{fontSize:14}}>
                                                    <td><img src={default1} style={{ width: 25, height: 25, borderRadius: 50, marginRight: 4 }} />{item.name + ' ' + item.middlename}</td>
                                                    <td>{item.role}</td>
                                                    <td >{item.reference}</td>
                                                    <td>{api.geDateFormat(item.createdAt)}</td>
                                                    <td>{item.currency}. {item.amount}</td>
                                                    <td >
                                                        <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                                            <label style={{ marginTop: 8}}>{item.currency}. {item.count_used}</label>
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge count={5} size="small" style={{ backgroundColor: '#52c41a', marginRight: 10 }}>
                                                            <label style={{ marginTop: 8}}>{item.currency}. {item.deposit_amount}</label>
                                                        </Badge>
                                                    </td>
                                                    <td>{item.currency}. {item.reste}</td>
                                                    <td>
                                                        <Button
                                                            style={{
                                                                height: 25,
                                                                padding: 0,
                                                                width: 60
                                                            }}
                                                            onClick={() => showPrint(item.userPartnerId, item)}
                                                        >
                                                            Print
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {getButton(`${item.count_used}`, `${item.deposit_amount}`, `${item.reste}`, `${item.amount}`, () => showModal(`${item.phone}`, `${item.id}`, `${item.name}`))}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            style={{
                                                                height: 25,
                                                                padding: 0,
                                                                width: 60
                                                            }}
                                                            onClick={() => navigate(`/seller_account/${item.userPartnerId}`)}
                                                        >
                                                            More
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>

                        </>
                    )
                }

            </div>


            <Modal
                title="REQUEST"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={false}
            >
                <>
                    <div className="modal-body">
                        <input type="text" name='id' value={id} onChange={(e) => setId(e.target.value)} style={{ display: "none" }} />
                        <div className="basic-form">
                            <div className="d-flex mb-2 align-items-center p-1 rounded border border-primary">

                                <img className="me-3" width="49" height="49" style={{ borderRadius: 10 }} src={phone1} />
                                <div>
                                    <p className="text-primary fs-14 mb-0 ml-1">Seller: {nameSeller} phone number</p>
                                    <span className="text-primary font-w600 ml-1">{phone}</span>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Write a reminder message</label>
                                <textarea id="montant" className="default-select form-control wide"
                                    placeholder="Message Request" rows={5}
                                />
                            </div>
                            <div className="select-card-wrapper" id="packe"></div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                            id="btn-Withdraw">Send Request</button>
                    </div>
                </>
            </Modal>

            <Modal
                title="View information to print"
                open={toprint}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancelPrint}
                footer={false}
                width={1000}
            >
                <div>

                    <ReactToPrint
                        trigger={() => <button
                            className="btn btn-primary btn-rounded">
                            Click to | Print</button>}
                        content={() => componentRef.current}
                    />

                    <div ref={componentRef}>
                        <div className="row">
                            <div className="col-lg-12 ml-3 mr-10">

                                <div className=" mt-3">
                                    {/* <div className=""> Invoice <strong>01/01/01/2018</strong> <span className="float-right">
                                        <strong>Status:</strong> Pending</span> </div> */}
                                    <div className="">
                                        <div className="row mb-3">

                                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-lg-end justify-content-md-left justify-content-xs-start">
                                                <div className="row align-items-left">
                                                    <div className="col-sm-9">
                                                        <div className="brand-logo mb-3">
                                                            <img className="logo-abbr me-2" src={logoLight} alt="" />
                                                            {/* <img className="logo-compact" src={logoSm} alt="" /> */}
                                                        </div>
                                                        <span><strong className="d-block">Tiva Heath</strong> The following specifications must appear on all transactions relating to transactions
                                                        </span>

                                                    </div>
                                                    {/* <div className="col-sm-3 mt-3"> <img src="images/qr.png" alt="" className="img-fluid width110" /> </div> */}
                                                </div>
                                            </div>

                                            <div className="mt-4 col-xl-3 col-lg-3 col-md-12 col-sm-6">
                                                <h6>To:</h6>
                                                <div> <strong>{name} {middleName}</strong> </div>
                                                <div>Role: {role}</div>
                                                <div style={{ paddingBottom: 40 }}>Phone: {phone}</div>
                                                <div><span style={{ fontSize: 26, color: '#68C4EE' }}>Transaction Details </span><hr /></div>
                                            </div>

                                        </div>

                                        <div className="table-responsive">
                                            <table className=" table-striped" style={{
                                                width: '100%',
                                                marginBottom: '1rem',
                                                color: '#797979'
                                            }}>
                                                <thead >
                                                    <tr style={{
                                                        borderBottomWidth: '1px',
                                                        fontSize: '14px',
                                                        fontWeight: 600,
                                                        letterSpacing: '0.5px',
                                                        borderColor: '#f0f1f5'

                                                    }}>
                                                        <th className="center"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}
                                                        >#</th>
                                                        <th style={{
                                                            verticalAlign: 'bottom',
                                                            borderBottom: '1px solid #dee2e6'
                                                        }}>Serie</th>
                                                        <th style={{
                                                            verticalAlign: 'bottom',
                                                            borderBottom: '1px solid #dee2e6'
                                                        }}>Product</th>
                                                        <th className="right"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Status</th>
                                                        <th className="center"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Finance</th>
                                                        <th className="right"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Surplier Amount
                                                            from used</th>
                                                        <th className="right"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Surplier Paid</th>
                                                        <th className="right"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Surplier Unpaid</th>
                                                        <th className="right"
                                                            style={{
                                                                verticalAlign: 'bottom',
                                                                borderBottom: '1px solid #dee2e6'
                                                            }}>Amount
                                                            / Collected</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        LoadInventory.map((item, i) => (
                                                            <tr style={{
                                                                padding: '.75rem',
                                                                verticalAlign: 'top',
                                                                borderTop: '1px solid #dee2e6'
                                                            }}>
                                                                <td className="center" style={{ padding: '.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{i + 1}</td>
                                                                <td className="left strong" style={{ padding: '.75rem', verticalAlign: 'top', borderTop: '1px solid #dee2e6' }}>{item.series}</td>
                                                                <td className="left"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex' }}> <span style={{ marginRight: 5 }}>Currenry:</span>{item.currency}.{item.amount_selling_prince}</span> <br />
                                                                    <span>Pins : </span>
                                                                    {item.pins} <br />
                                                                    <span>Total seies Amount <br /> Value:</span>{item.currency}.{parseInt(item.pins) * parseInt(item.amount_selling_prince)}</td>
                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex', marginTop: 0 }}> <span style={{ marginRight: 5 }}>Used:</span>{item.status_used}</span><br />
                                                                    <span style={{ display: 'flex' }}> <span style={{ marginRight: 5 }}>Assigned:</span>{item.status_asigned}</span><br />
                                                                    <span style={{ display: 'flex' }}> <span style={{ marginRight: 5 }}>Available Pins:</span>{item.available_pins}</span></td>

                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex' }}> <span style={{ marginRight: 5 }}>Used:</span>{item.currency}.{item.finance_amount_used}</span> <br />
                                                                    <span>Paid surplier: </span>
                                                                    {item.finance_amount_paid_surplier} <br />
                                                                    <span>Unpaid:</span>{item.currency}.{item.finance_amount_unpaid}</td>

                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.finance_amount_used}</span></td>

                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.finance_amount_paid_surplier}</span></td>

                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.finance_amount_unpaid}</span></td>

                                                                <td className="right"
                                                                    style={{
                                                                        padding: '.75rem',
                                                                        verticalAlign: 'top',
                                                                        borderTop: '1px solid #dee2e6'
                                                                    }}><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.total_amount_pay_collect}
                                                                    </span></td>
                                                            </tr>


                                                        ))
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-5"> </div>
                                            <div className="col-lg-4 col-sm-5 ms-auto">
                                                <table className="table table-clear">
                                                    <tbody>
                                                        <tr>
                                                            <td className="left"><strong>Subtotal</strong></td>
                                                            <td className="right">Tsh.{sommeTotal}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left"><strong>Discount ({resultDiscount}%)</strong></td>
                                                            <td className="right">Tsh.{sommeDiscount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left"><strong>VAT ({resultTva}%)</strong></td>
                                                            <td className="right">Tsh.{sommeVatAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="left"><strong>Total</strong></td>
                                                            <td className="right"><strong>Tsh.{sommeTotal}</strong></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal >

        </>
    )
}

export default BackToCollect