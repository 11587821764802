import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const SecurityParams = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState(api.initUser())
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newConfirm, setnewConfirm] = useState('')


    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
    }, []);

    const SecurityInfos = async () => {
        let data = JSON.stringify({
            "name": name,
            "old_password": oldPassword,
            "new_password": newPassword,
            "new_password_confirmation": newConfirm
        });

        setLoading(true);

        if (newPassword != newConfirm) {
            toast.error('The New Password field and the Confirm Password field must be the same');

        } else if (newPassword === '' || newConfirm === '' || name === '' || name === '') {
            toast.error('No field should be empty');
        }
        else {
            api.getHttpRequest("/user/profile/update-security-infos", data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {

                    toast.success(json.message);
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                // setLoading(false)       
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    return (
        <>
            {/** Start container-fluid */}

            <div className="container-fluid1">

                <div className="row pb-5">
                    <div className="col-lg-8">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                <div className="input-group search-area d-lg-inline-flex">
                                    <div className="input-group-append">
                                        <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search here..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2">
                        <div className="d-flex weather-detail">
                            <span id="invest_status_pan" style={{ backgroundColor: '#1EAAE7', color: 'white' }}><i
                                className="las la-scan"></i> <a id="invest_status">Active</a></span> Status
                        </div>
                    </div>
                </div>


                <div className="col-xl-12 card stacked-2 py-5 pt-2">
                    <div className="row col-sm-12">
                        <div>
                            <Toaster
                                position="bottom-left"
                                reverseOrder={false}

                            />
                        </div>
                        <div className="col-xl-6">
                            <h4 className="fs-20 text-black pb-2">Security parameters
                            </h4>
                            <div className="basic-form" >

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">User name</label>
                                        <input type="text" className="form-control" id="name"
                                            name='name'
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nom" className="form-label">Old password</label>
                                        <input type="password" className="form-control" id="ancien_password" placeholder="Old password"
                                            name='oldPassword'
                                            value={oldPassword}
                                            onChange={(e) => { setOldPassword(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label">New password</label>
                                        <input type="password" className="form-control" id="nouveau_password" placeholder="New password"
                                            name='newConfirm'
                                            value={newPassword}
                                            onChange={(e) => { setNewPassword(e.target.value) }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label">Confirm password</label>
                                        <input type="password" className="form-control" id="confirm_password" placeholder="Confirm password"
                                            name='newConfirm'
                                            value={newConfirm}
                                            onChange={(e) => { setnewConfirm(e.target.value) }}
                                        />
                                    </div>
                                    <div id="infos"></div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email" className="form-label" style={{ color: '#fff' }}>.</label>

                                        {!Loading && (
                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" onClick={SecurityInfos}>Update profile</button>
                                        )}

                                        {Loading && (

                                            <button type="button" id="btn_update" className="btn btn-primary agree-link" disabled>
                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/** end container-fluid */}
        </>
    )
}

export default SecurityParams
