import React, { useEffect, useState } from 'react'
import { loginImage } from '../../../components/icons'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Login = () => {

	const navigate = useNavigate()
	const [Loading, setLoading] = useState(false);

	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState("");

	const changePhoneNumber = (value: string, country: any) => {
		const countryCode = country.dialCode;
		const phoneNumber = value.replace(countryCode, `+${countryCode}`);
		setUsername(phoneNumber);
	  };

	useEffect(() => {
		const storedUsername = localStorage.getItem("phone_account");
		if (storedUsername === null || storedUsername === "") {
			setUsername("");
		} else {
			setUsername(storedUsername);
		}
	}, []);


	const loginSubmit = async () => {
		let data = JSON.stringify({
			"username": username,
			"password": password
		});
		setLoading(true);

		if (username === '' || password === '') {
			toast.error('The Email and Password fields must not be empty');
		} else {
			api.getHttpRequestNotToken("/user/login", data, 'POST').then((response) => {
				const json = response.data;
				if (json.status == 200) {
					setUsername('');
					setPassword('');
					// Définir la clé de chiffrement
					const key = 'ma_clé_de_chiffrement_token_user';
					const key_user = 'ma_clé_de_chiffrement_user_connecter'
					const key_avatar = "cle_avatar"
					const key_role = "its_role_key"
					// Définir la variable à crypter
					const token = json.data.token;
					const user_connect = json.data.username;
					const user_avatar = json.data.avatar;
					const user_role = json.data.role;
					const tokenCrypter = CryptoJS.AES.encrypt(token, key).toString();
					const userconnectCrypter = CryptoJS.AES.encrypt(user_connect, key_user).toString();
					const avatarCrypter = CryptoJS.AES.encrypt(user_avatar, key_avatar).toString();
					const userRoleCrypter = CryptoJS.AES.encrypt(user_role, key_role).toString();

					sessionStorage.setItem('onktxonktx', tokenCrypter);
					sessionStorage.setItem('sutielzatiwo', userconnectCrypter);
					sessionStorage.setItem('obvokotoratebo', avatarCrypter);
					sessionStorage.setItem('bobaonsraoulexajs', userRoleCrypter);
					if (json.data.role === 'Vendor') {
						navigate('/vendor')
					} else {
						navigate('/home')
					}

				} else if(json.status == 401 || json.status == 403){
                    navigate('/login')
					toast.error('The token entered is in use');
				} else {
					toast.error(json.message);
				}
			}).catch((error) => {
				if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
					toast.error("Please connect your device to the internet");
				} else if (error.response && error.response.data && error.response.data.message) {
					toast.error(error.response.data.message);
				} else {
					toast.error(error.message);
				}
			})

		}

		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}

	return (
		<>
			<div className="limiter">
				<div className="container-login100">
					<div className="wrap-login100">
						<div className="login100-form validate-form">
							<div>
								<Toaster
									position="top-right"
									reverseOrder={false}
								/>
							</div>
							<h2 className="header-title mb-3 font-30 font-weight-normal border-0 text-center">
								Login to continue
							</h2>
							<label>Phone number</label>
							<div className="wrap-input100" data-validate="Valid email is required: ex@abc.xyz">
								{/* <input className="input100" type="text"
									name="username"
									onChange={(e) => setUsername(e.target.value)}
									value={username}
									placeholder='Email or Phone number'
								/> */}
								
								<PhoneInput
									inputStyle={{
										display: 'block',
										width: '100%',
										background: '0 0',
										fontFamily: 'Poppins, sans-serif',
										fontSize: '18px',
										color: '#555',
										lineHeight: '1.2',
										height: '100%',
                                        WebkitTransition: 'all .4s',
								        oTransition: 'all .4s',
								        mozTransition: 'all .4s',
								        transition: 'all .4s',
										
										
									} as React.CSSProperties}
									inputClass={'wrap-input100, validate-input'}
								country={'tz'}
								value={username}
								onChange={changePhoneNumber}
								enableSearch={true}
								countryCodeEditable={true}
								/>
								{/* <span className="focus-input100" style={{ paddingLeft: 25, paddingTop: 5 }}>Email or Phone number</span> */}
								{/* <span className="label-input100">Phone number</span> */}
							</div>
							<label>Password</label>
							 <div className="wrap-input100 validate-input" data-validate="Password is required">
								<input className="input100" type="password" name="password"
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									placeholder='Password'
								/>
								{/* <span className="focus-input100" style={{ paddingLeft: 25, paddingTop: 5 }}>Password</span> */}
								{/* <span className="label-input100">Password</span> */}
							</div>
							<div className="flex-sb-m w-full py-4">
								<div className="contact100-form-checkbox">
									<input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
									<label className="label-checkbox100" htmlFor="ckb1">
										Remember me
									</label>
								</div>
								<div>
									<Link to="/forgot_pswd" className="txt1">
										Forgot Password?
									</Link>
								</div>
							</div>
							<div className="container-login100-form-btn">

								{!Loading && (
									<button type="submit" className="login100-form-btn" onClick={loginSubmit}>
										Sign in
									</button>
								)}

								{Loading && (

									<button type="submit" className="login100-form-btn" disabled>
										<i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 20 }}></i> <span>Ongoing processing...</span>
									</button>
								)}
							</div>
							<div className=" py-4">
								<span className="txt2">
									Don't have an account?{" "}
									<Link to="/account" className="txt1">
										Sign Up Here
									</Link>
								</span>
							</div>
							<div className="text-center py-4">
								<span className="txt2">
									or sign up using
								</span>
							</div>
							<div className="login100-form-social flex-c-m">
								<a href="#" className="login100-form-social-item flex-c-m bg1 mr-2">
									<i className="mdi mdi-facebook" aria-hidden="true"></i>
								</a>
								<a href="#" className="login100-form-social-item flex-c-m bg2 mr-2">
									<i className="mdi mdi-twitter" aria-hidden="true"></i>
								</a>
							</div>
						</div>
						<div className="login100-more" style={{ backgroundImage: `url(${loginImage})` }}>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login
