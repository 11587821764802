import React from 'react'
import AppRoutes from '../AppRoutes'

const PageContent = () => {
  return (
    
         <AppRoutes/>
    
  )
}

export default PageContent
