import { Link, useNavigate} from "react-router-dom"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import api from '../../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import requestTopay from "../../../../controller/requestTopay";

const PaymentHistory = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    const [loadPay, setLoadPay] = useState<requestTopay[]>([]);

    useEffect(() => {
        loadPayement();
    },[]);

    function loadPayement() {
        api.getHttpRequest(`/partners/pay-load`, data, 'GET').then((response) => {
            const json = response.data;
       
                setLoadPay(json.data.rows);
           
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    function getStatus(element:any){
        if (element === 'PENDING'){
            return ['btn-warning btn-sm rounded font-16']
        } else if (element === 'SUCCESS'){
            return ['btn-success btn-sm rounded font-16']
        } else if (element === 'ERROR'){
            return ['btn-danger btn-sm rounded font-16']
        }
        return ''
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {
                        isMatch ? (
                            <>
                                <div>
                                    <div className="mb-2 ml-2 d-block d-sm-flex border-0">
                                        <div className="card-action card-tabs  mt-sm-0">
                                            <ul className="nav nav-tabs" role="tablist">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" id="Weekly_" to="/pay"
                                                        role="tab">Pay</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link " id="Monthly_" to="/vendor"
                                                        role="tab">Home</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link " id="Today_" to="/stock-vendor"
                                                        role="tab">In Stock</Link>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div>
                                        <h2 className="header-title pb-0 font-30 border-none">Payement History</h2>
                                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                            <label style={{
                                                backgroundColor: 'red',
                                                height: 20,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <p style={{ paddingLeft: 2 }}>List of payments</p>
                                        </div>
                                    </div>
                                </div>


                            </>
                        ) : (
                            <>
                                <div className="col-9">
                                    <div>
                                        <h2 className="header-title pb-0 font-30 border-none">Payment History
                                        </h2>
                                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 1 }}>
                                            <label style={{
                                                backgroundColor: 'red',
                                                height: 20,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <p style={{ paddingLeft: 2 }}>List of payments</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="mb-2 ml-5 d-block d-sm-flex border-0">
                                        <div className="card-action card-tabs  mt-sm-0">
                                            <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                    <Link className="nav-link active" id="Today_" to="/payment-history"
                                                        role="tab">List of payments</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link " id="Monthly_" to="/vendor"
                                                        role="tab">Home</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link " id="Weekly_" to="/pay"
                                                        role="tab">Pay</Link>
                                                </li>
                                                
                                                {/* <li className="nav-item">
                                                    <Link className="nav-link" id="Today_" to="/stock-vendor"
                                                        role="tab">In Stock</Link>
                                                </li> */}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>

                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header d-block d-sm-flex border-0">
                            <div>
                                <h4 className="fs-20 text-black">List of payments</h4>

                            </div>
                            <div className="card-action card-tabs mt-3 mt-sm-0">
                                {/* <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="Monthly_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Monthly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Weekly_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Weekly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="Today_" href="/" onClick={api.ReturnVoid0}
                                            role="tab">Today</a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="card-body payment-bx tab-content p-0">
                            <div className="tab-pane active show fade" role="tabpanel">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table header-border table-responsive-sm tbl-common">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Mode payment</th>
                                                    {/* <th>Dates</th> */}
                                                    <th>Amount</th>
                                                    <th>amount pay</th>
                                                    <th>Referency</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tab_">

                                                {
                                                    loadPay.map((item, i) => (
                                                        <tr key={i} style={{ height: 1 }}>
                                                            <td>{i + 1}</td>
                                                            <td>{item.mode_payment}</td>
                                                            <td>{item.currency}.{item.amount}</td>
                                                            <td><span className="text-muted">{item.currency}.{item.amount_pay_later}</span></td>
                                                            <td className="serial"><a href="/" onClick={api.ReturnVoid0}>{item.reference}</a></td>
                                                            <td><span className={getStatus(item.status)[0]}>{item.status}</span></td>
                                                            {/* <td><span className={getStaus0(item.status)[0]}><i
                                                                className={getStaus1(item.status)[0]}></i>{getStaus0(item.status)[1]}</span>
                                                            </td> */}
                                                            {/* <td>{item.number_reference}</td> */}
                                                            <td>{api.geDateFormat(item.createdAt)}</td>
                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <nav>
                                        <ul className="pagination pagination-lg mb-0">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">«</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">»</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentHistory