import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import api from '../../config/Config'
import requestRow from '../../controller/RequestRow';
import {
    SearchOutlined, FileExcelOutlined
} from "@ant-design/icons"
import { Pagination, Input, Select } from 'antd';
import { default1 } from '../../components/icons';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { Card, Avatar, Dropdown, Menu, Modal } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import Popup from '../../components/popup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import zIndex from '@mui/material/styles/zIndex';
const { Option } = Select;
const { Meta } = Card;

const menu = (
    <Menu>
        <Menu.Item key="1">Modifier</Menu.Item>
        <Menu.Item key="2">Supprimer</Menu.Item>
    </Menu>
);

const Sellers = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const data = '';
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState<string>('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [gender, setGender] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [pswd, setPswd] = useState('');
    const [confpass, setConfPass] = useState('');

    const [minicipalName, setMinicipalName] = useState('');
    const [query, setQuery] = useState('')
    const [searchResult, setSearchResult] = useState([]);
    const [viewPopup, SetViewPopup] = useState(false)

    // Variables Load elements
    const [province, setPronvince] = useState<requestRow[]>([])
    const [city, setCity] = useState<requestRow[]>([])
    const [minicipality, setMinicipality] = useState<requestRow[]>([])
    const [categoriRole, setCategoriRole] = useState<requestRow[]>([])
    const [sellerLoad, setSellerLoad] = useState<requestRow[]>([])

    //Paginate
    const [totalPage, setTotalPage] = useState(1);
    // Show Modal
    const [modal1Open, setModal1Open] = useState(false);

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setPhone(phoneNumber);
    };

    function ViewPop() {
        SetViewPopup(true)
    }

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') === null) {
            navigate('/login')
        }
        loadProvince()
        loadCity()
        loadMinicipality()
        loadCategorie_or_Role()
        loadSeller(1)
        // Promise.all([loadProvince(), loadCity(), loadMinicipality(), loadCategorie_or_Role(), loadSeller(1)])
        // .catch((error) => {
        //     if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        //         toast.error("Pas de connexion internet");
        //     } else {
        //         toast.error(error.response.data.message);
        //     }
        // });
    }, []);

    //Load seller
    function loadSeller(page: any) {
        api.getHttpRequest(`/member/load-vendor-agent-distrib?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setSellerLoad(json.data.rows);
                setSearchResult(json.data.rows);
                setTotalPage(json.data.count);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }
    //Add Seller_or_Member
    const Add_Seller_or_Member = async () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("phone", phone);
        formData.append("MunicipalityId", minicipal);
        formData.append("avatar", avatar);
        formData.append("gender", gender);
        formData.append("UserRoleId", userRoleId);
        formData.append("civil_status", civilStatus);
        formData.append("date_of_birth", dateOfBirth);
        formData.append("place_of_birth", placeOfBirth);
        formData.append("is_seller_account", 'true');
        formData.append("default_password", pswd);

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/member/create',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || lastname === '' || phone === ''
            || email === '' || minicipal === '' || dateOfBirth === '' || gender === '') {
            toast.error('No fields should be empty');
        } else if (!regexTelephone.test(phone)) {
            toast.error('The entry number is incoreact');
        } else if (!regexEmail.test(email)) {
            toast.error('The email address entered is incoreact');
        } else if (pswd != confpass) {
            toast.error('The password must be the same');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        toast.success(json.message)
                        loadSeller(1)
                        SetViewPopup(false)
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }
    //Load zones
    const loadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCategorie_or_Role = async () => {
        api.getHttpRequest("/parameters/roles/load?groupe=1", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCategoriRole(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestRow[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestRow[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
    }

    const searchSellers = (event: any) => {
        const getSearch = event.target.value;
        if (getSearch.length > 0) {
            const searchdata = sellerLoad.filter((item) => item.name.toLowerCase().includes(getSearch)
                || item.lastname.toLowerCase().includes(getSearch) || item.middlename.toLowerCase().includes(getSearch));
            setSellerLoad(searchdata)
        } else {
            setSellerLoad(searchResult)
        }
        setQuery(getSearch);
    }

    return (
        <>
            {/** Start container-fluid */}

            <div className="container-fluid1">


                {/* <div className="row pb-5">
                    <div className="col-lg-8">
                        <div className="header-left">
                            <div className="dashboard_bar">
                                <div className="input-group search-area d-lg-inline-flex">
                                    <div className="input-group-append">
                                        <button className="input-group-text"><i className="mdi mdi-magnify"></i></button>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search here..." value={query} onChange={(e) => searchSellers(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                    <div className="col-lg-2">
                        <div className="d-flex weather-detail">
                            <span id="invest_status_pan" style={{ backgroundColor: '#1EAAE7', color: 'white' }}><i
                                className="las la-scan"></i> <a id="invest_status">Active</a></span> Status
                        </div>
                    </div>
                </div> */}


                <div className="row">
                    <div className="col-md-12">
                        {
                            isMatch ? (
                                <>

                                    <div className="card stacked-2">
                                        <div className="card-header flex-wrap border-0 pb-0 align-items-end">
                                            <div>
                                                <h4 className="fs-20 text-black mb-0">Resellers Or Distribitor</h4>

                                            </div>
                                            <div className="header-left" >
                                                <span style={{ marginTop: 5, width: 280 }}
                                                    className="btn btn-primary btn-rounded mb-2 "
                                                    data-toggle="modal"
                                                    data-target="#add_new_seller"

                                                >
                                                    <i className="mdi mdi-plus scale5"></i>
                                                    New Sellers</span>
                                                <div className="dashboard_bar" style={{ marginRight: 5 }}>
                                                    <div className="input-group1 search-area1 d-lg-inline-flex" >
                                                        <Input style={{ color: 'black' }} size="large" placeholder="Searh here..." value={query} onChange={(e) => searchSellers(e)} prefix={<SearchOutlined />} />

                                                    </div>
                                                </div>
                                            </div>

                                            <span className="fs-12" style={{ paddingTop: 10 }}>List of resellers who have joined by JV</span>
                                        </div>
                                        <div className="card-body">
                                            {
                                                sellerLoad.map((item, i) => (
                                                    <div key={i} className="d-flex pb-3 border-bottom mb-3 align-items-center">
                                                        <img src={default1} alt="" className="rounded-circle mr-1" width="40" />
                                                        <div className="me-auto">
                                                            <div className='col-12'>
                                                                <div className='row'>

                                                                    <div className='col-10'>
                                                                        <h6 className="fs-16 font-w600 mb-0 " >
                                                                            <span >{item.name + ' ' + ' ' + item.lastname + ' ' + item.middlename}</span>

                                                                        </h6>
                                                                        <span className="fs-12">{item.gender} | Type : {item.Role} <br />Tel: {item.phone} </span>
                                                                    </div>

                                                                    <div className='col-2' style={{ width: 100 }}>
                                                                        <div className="dropdown" >
                                                                            <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                <MoreOutlined style={{ fontSize: 30 }} />
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-right" >
                                                                                <Link className="dropdown-item" to={`/seller_account/${item.id}`}>Seller
                                                                                    Account</Link>
                                                                                <a className="dropdown-item" href="#">Hang this
                                                                                    Dealer</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Pagination
                                            style={{
                                                paddingTop: 0,
                                                paddingBottom: 10
                                            }}
                                            pageSize={10}
                                            total={totalPage}
                                            onChange={(page: any) => {
                                                loadSeller(page)
                                            }}
                                        />
                                    </div>

                                </>
                            ) :
                                (<>

                                    <div className="card stacked-2">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card-box border-0 reseller-distributer">
                                                    <div className="card-header ">
                                                        {/* <h2 className="mt-0 font-24 mb-3 font-weight-500">Resellers Or Distribitor
                                            </h2> */}
                                                        {/* <div  style={{marginRight:100}}>
                                                <Input size="large" placeholder="Searh here..." prefix={<SearchOutlined />} />
                                            </div> */}
                                                        {/* <div className="header-left">

                                                <a href=""
                                                    className="btn btn-primary btn-rounded mb-2 " data-toggle="modal"
                                                    data-target="#add_new_seller">
                                                    <i className="mdi mdi-plus scale5"></i>
                                                    New Sellers</a>
                                            </div> */}

                                                        <div className="left-side">
                                                            <h2 className="m-0 font-24 font-weight-500">Resellers Or Distribitor</h2>
                                                            <p>List of resellers who have joined by jv
                                                            </p>
                                                        </div>
                                                        <div className="header-left">
                                                            <div className="dashboard_bar" style={{ marginRight: 5 }}>
                                                                <div className="input-group1 search-area1 d-lg-inline-flex" >
                                                                    {/* <div className="input-group-append">
                                                                <button className="input-group-text"><i
                                                                    className="mdi mdi-magnify"></i></button>
                                                            </div>
                                                            <input type="text" className="form-control"
                                                                placeholder="Search here..." value={query} onChange={(e) => searchSellers(e)} /> */}
                                                                    <Input style={{ color: 'black' }} size="large" placeholder="Searh here..." value={query} onChange={(e) => searchSellers(e)} prefix={<SearchOutlined />} />


                                                                </div>
                                                            </div>
                                                            <span style={{ marginTop: 13 }}
                                                                className="btn btn-primary btn-rounded mb-2 "
                                                                // data-toggle="modal"
                                                                // data-target="#add_new_seller"
                                                                onClick={() => ViewPop()}
                                                            >
                                                                <i className="mdi mdi-plus scale5"></i>
                                                                New Sellers</span>

                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table 
                                                         className="table header-border table-responsive-sm tbl-common">
                                                            <thead>
                                                                <tr>
                                                                    <th>

                                                                        {/* <div className="checkbox checkbox-single checkbox-primary">
                                                                            <input type="checkbox" className="custom-control-input"
                                                                                id="action-checkbox" />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="action-checkbox">&nbsp;</label>
                                                                        </div> */}
                                                                        Avatar
                                                                    </th>
                                                                    <th>Name</th>
                                                                    <th>Roll No</th>
                                                                    <th>Gender</th>
                                                                    <th>Phone</th>
                                                                    <th>Category</th>
                                                                    <th>Address</th>
                                                                    {/* <th>Created at</th> */}
                                                                    <th>Options</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    sellerLoad.map((item, i) => (
                                                                        <tr key={i}>
                                                                            <td >
                                                                                {/* <div className="checkbox checkbox-primary  float-left">
                                                                                    <input style={{marginTop:-1}} id="checkbox2" type="checkbox" />
                                                                                    <label htmlFor="checkbox2"></label>
                                                                                </div> */}

                                                                                <img src={api.var_.url_avatar + item.avatar}
                                                                                    alt="contact-img" title="contact-img"
                                                                                    className="rounded-circle avatar-sm" />
                                                                            </td>

                                                                            <td>{item.name ? item.name : '' + ' ' + ' ' + item.lastname ? item.lastname : '' + ' ' + item.middlename ? item.middlename : ''}</td>

                                                                            <td> {item.personnel_number}</td>

                                                                            <td>{item.gender}</td>

                                                                            <td> {item.phone}</td>
                                                                            <td> {item.Role}</td>
                                                                            <td>{item.municipalityName + ' / ' + item.address}</td>
                                                                            {/* <td>
                                                    June 17, 2023 at 12:14 PM
                                                </td> */}

                                                                            <td>
                                                                                <div className="dropdown">
                                                                                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="ti-more-alt font-20"></i>
                                                                                    </button>
                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                        <Link className="dropdown-item" to={`/seller_account/${item.id}`}>Seller
                                                                                            Account</Link>
                                                                                        <a className="dropdown-item" href="#">Hang this
                                                                                            Dealer</a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <Pagination
                                                        style={{
                                                            paddingTop: 10
                                                        }}
                                                        pageSize={10}
                                                        total={totalPage}
                                                        onChange={(page: any) => {
                                                            loadSeller(page)
                                                        }}
                                                    />
                                                    {/* <ul className="pagination pagination-lg mt-3">
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul> */}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>)

                        }

                    </div>

                </div>

            </div >

            {/** end container-fluid */}


            {/** add new seller Modal */}
            <div className="modal fade" id="add_new_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add or Update reseller
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="alert alert-info" role="alert">
                                Before creating a reseller account you must make sure you know the person well, especially their
                                real identity, have their photo and physical address and must be able to assume the role of
                                seller of Tiva products.
                            </div>
                            <div className="col-md-12">
                                <div>
                                    <Toaster
                                        position="bottom-left"
                                        reverseOrder={false}

                                    />
                                </div>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="pro-pic " >
                                                    <div className="profile-pic-wrapper">
                                                        <div className="pic-holder mb-0">
                                                            {/** uploaded pic shown here */}
                                                            <img id="profilePic" className="pic" src={default1} />

                                                            <input className="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{ opacity: 0 }}
                                                                onChange={handleImageChange}
                                                            />
                                                            <label htmlFor="newProfilePhoto" className="upload-file-block">
                                                                <div className="text-center">
                                                                    <div className="mb-2">
                                                                        <i className="fa fa-camera fa-2x"></i>
                                                                    </div>
                                                                    <div className="text-uppercase">
                                                                        Update <br /> Profile Photo
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-8 row' >
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Name</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='name'
                                                        value={name}
                                                        onChange={(e) => { setName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">LastName</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='lastname'
                                                        value={lastname}
                                                        onChange={(e) => { setLastName(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">MiddleName</label>
                                                    <input type="text" className="form-control" placeholder="" id="fullname"
                                                        name='middlename'
                                                        value={middlename}
                                                        onChange={(e) => { setMiddlename(e.target.value); }}
                                                    />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label">Category</label>
                                                    <select className="form-control" id="categorie"
                                                        name='userRoleId'
                                                        value={userRoleId}
                                                        onChange={(e) => { setUserRoleId(e.target.value) }}
                                                    >
                                                        <option value="">Select category</option>
                                                        {
                                                            categoriRole.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }


                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <input type="hidden" id="id" value="00" /> */}


                                    <div className='col-md-12 row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Gender</label>
                                            <select className="form-control" id="gender"
                                                name='gender'
                                                value={gender}
                                                onChange={(e) => { setGender(e.target.value) }}
                                            >
                                                <option>Choose...</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Date of birth</label>
                                            <input type="date" className="form-control" placeholder="" id="datenaiss"
                                                name='dateOfBirth'
                                                value={dateOfBirth}
                                                onChange={(e) => { setDateOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Place of Birth</label>
                                            <input type="text" className="form-control" placeholder="" id="phone"
                                                name='placeOfBirth'
                                                value={placeOfBirth}
                                                onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                            />
                                        </div>
                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" placeholder="" id="phone"
                                                name='phone'
                                                value={phone}
                                                onChange={(e) => { setPhone(e.target.value) }}
                                            />
                                        </div>

                                        <div className="mb-3  col-md-4">
                                            <label className="form-label">Adress Mail</label>
                                            <input type="email" className="form-control" placeholder="" id="email"
                                                name='email'
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value); }}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Region</label>
                                            {/* <select className="form-control" id="Province" placeholder="Email"
                                                onChange={(e) => GetProvince(e.target.value)}
                                            >
                                                <option value=''>Select State/Region</option>
                                                {
                                                    province &&
                                                        province !== undefined ?
                                                        province.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.name}</option>
                                                        ))
                                                        : "No Province"
                                                }
                                            </select> */}
                                            <Select showSearch style={{ width: 225, height: 51 }} dropdownStyle={{ height: 200 }}

                                                placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                                filterOption={(input: string, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                <Option selected value="">Select State/Region
                                                </Option>
                                                {
                                                    province &&
                                                        province !== undefined ?
                                                        province.map((item, i) => (
                                                            <Option key={i} value={item.id}>{item.name}</Option>
                                                        ))
                                                        : "No Province"
                                                }
                                            </Select>
                                        </div>
                                        <div className="mb-4  col-md-6">
                                            <label className="form-label">City</label>
                                            <select className="form-control" id="City" placeholder="Email"
                                                onChange={(e) => GetCity(e.target.value)}
                                            >
                                                <option value=''>Select City</option>
                                                {
                                                    stateCity &&
                                                        stateCity !== undefined || stateCity !== null ?
                                                        stateCity.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                        : "No City"
                                                }

                                            </select>
                                        </div>

                                        <div className="mb-4  col-md-6">
                                            <label className="form-label">Street</label>
                                            <select className="form-control" id="Minicipality" placeholder="Email"
                                                name='minicipality'
                                                value={minicipal}

                                                onChange={(e) => { setMinicipal(e.target.value) }}
                                            >
                                                <option value=''>Select Street</option>
                                                {
                                                    stateMinicipality &&
                                                        stateMinicipality !== undefined || stateMinicipality !== null ?
                                                        stateMinicipality.map((item, i) => {
                                                            return (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            )
                                                        })
                                                        : "No minicipality"
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-4  col-md-12">
                                            <label className="form-label">Adresse</label>
                                            <textarea className="form-control" placeholder="" id="adresse"
                                                name='adress'
                                                value={adress}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                            ></textarea>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">


                            {!Loading && (
                                <button type="button" className="btn btn-primary agree-link font-18"
                                    id="btn-Withdraw"
                                    onClick={() => (Add_Seller_or_Member())}
                                >Save changes</button>
                            )}

                            {Loading && (

                                <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                    id="btn-Withdraw"

                                >
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Popup
                title='Add or Update reseller'
                openPupop={viewPopup}
                setOpenPopup={SetViewPopup}
            >
                <div className="alert alert-info" role="alert">
                    Before creating a reseller account you must make sure you know the person well, especially their
                    real identity, have their photo and physical address and must be able to assume the role of
                    seller of Tiva products.
                </div>
                <div className="col-md-12">
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}

                        />
                    </div>
                    <div className="row">

                        {/* <input type="hidden" id="id" value="00" /> */}


                        <div className='col-md-12 row'>

                            <div className="mb-1 col-md-4">
                                <label className="form-label">Name</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='name'
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">LastName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='lastname'
                                    value={lastname}
                                    onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">MiddleName</label>
                                <input type="text" className="form-control" placeholder="" id="fullname"
                                    name='middlename'
                                    value={middlename}
                                    onChange={(e) => { setMiddlename(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">Category</label>
                                <select className="form-control" id="categorie"
                                    name='userRoleId'
                                    value={userRoleId}
                                    onChange={(e) => { setUserRoleId(e.target.value) }}
                                >
                                    <option value="">Select category</option>
                                    {
                                        categoriRole.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }


                                </select>
                            </div>

                            <div className="mb-1 col-md-4">
                                <label className="form-label">Gender</label>
                                <select className="form-control" id="gender"
                                    name='gender'
                                    value={gender}
                                    onChange={(e) => { setGender(e.target.value) }}
                                >
                                    <option>Choose...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>

                            <div className="mb-1 col-md-4">
                                <label className="form-label">Date of birth</label>
                                <input type="date" className="form-control" placeholder="" id="datenaiss"
                                    name='dateOfBirth'
                                    value={dateOfBirth}
                                    onChange={(e) => { setDateOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label">Place of Birth</label>
                                <input type="text" className="form-control" placeholder="" id="phone"
                                    name='placeOfBirth'
                                    value={placeOfBirth}
                                    onChange={(e) => { setPlaceOfBirth(e.target.value) }}
                                />
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label">Phone</label>
                                {/* <input type="text" className="form-control" placeholder="" id="phone"
                                    name='phone'
                                    value={phone}
                                    onChange={(e) => { setPhone(e.target.value) }}
                                /> */}
                                <PhoneInput
                                    inputStyle={{
                                        width: '100%',

                                    } as React.CSSProperties}
                                    inputClass={'form-control'}
                                    country={'tz'}
                                    value={phone}
                                    onChange={changePhoneNumber}
                                    enableSearch={true}
                                    countryCodeEditable={true}
                                />
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Adress Mail</label>
                                <input type="email" className="form-control" placeholder="" id="email"
                                    name='email'
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1 col-md-4">
                                <label className="form-label">Province</label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select State/Region
                                    </Option>
                                    {
                                        province &&
                                            province !== undefined ?
                                            province.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No Province"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-4">
                                <label className="form-label">District</label>

                                <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" onChange={(e) => GetCity(e)}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option selected value="">Select District
                                    </Option>
                                    {
                                        stateCity &&
                                            stateCity !== undefined || stateCity !== null ?
                                            stateCity.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No City"
                                    }
                                </Select>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Ward/Kata</label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select minicipality" optionFilterProp="children" value={minicipal} onChange={(e) => { setMinicipal(e) }}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select/Minicipality
                                    </Option>
                                    {
                                        stateMinicipality &&
                                            stateMinicipality !== undefined || stateMinicipality !== null ?
                                            stateMinicipality.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No minicipality"
                                    }
                                </Select>
                            </div>
                            <div className="mb-1  col-md-12">
                                <label className="form-label">Address</label>
                                <textarea className="form-control" placeholder="" id="adresse"
                                    name='adress'
                                    value={adress}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                ></textarea>
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Default password</label>
                                <input type="password" className="form-control" placeholder=""

                                    name='pswd'
                                    value={pswd}
                                    onChange={(e) => { setPswd(e.target.value) }}
                                />
                            </div>

                            <div className="mb-1  col-md-4">
                                <label className="form-label">Confirm password</label>
                                <input type="password" className="form-control" placeholder=""
                                    name='confpass'
                                    value={confpass}
                                    onChange={(e) => { setConfPass(e.target.value); }}
                                />
                            </div>
                            <div className="mb-1  col-md-4">
                            <label className="form-label text-white">submit</label>
                                {!Loading && (
                                    <button type="button" className="btn btn-primary agree-link font-18"
                                        id="btn-Withdraw"
                                        onClick={() => (Add_Seller_or_Member())}
                                    >Save changes</button>
                                )}

                                {Loading && (

                                    <button type="button" className="btn btn-primary agree-link font-18" disabled={true}
                                        id="btn-Withdraw"

                                    >
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 25 }}></i> <span>Ongoing processing...</span>
                                    </button>
                                )}

                            </div>


                        </div>

                    </div>

                </div>


            </Popup>
        </>
    )
}

export default Sellers
