import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import {
    Card, Row, Col, Badge, Drawer, List, Input, Button,
    Checkbox,
    Pagination,
    Modal,
    Tabs
} from "antd";

import {
    SearchOutlined, FileExcelOutlined, MoreOutlined
} from "@ant-design/icons"
import { default1 } from '../../components/icons';
import requestRow from '../../controller/RequestRow';
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import BackToCollect from './collect';
import PayNext from './paynext';
import SurplierPayout from './surplierpayout';
import Earning from './earning';
import { LiaNetworkWiredSolid } from 'react-icons/lia'
import { BsXDiamond } from 'react-icons/bs';
import { BsQuestionDiamond } from 'react-icons/bs';
import {FcSimCardChip } from 'react-icons/fc';
import {FaMoneyCheckDollar} from 'react-icons/fa6';
import {LiaMoneyCheckSolid} from 'react-icons/lia'
import {FaMoneyCheckAlt} from 'react-icons/fa';
import {BsExclamationDiamond} from 'react-icons/bs';

type MyCustomCSS = React.CSSProperties & {
    textAlign?: string;
};

const Data = {
    count_used: Number,
    deposit_amount: Number,
    reste: Number,
    amount: Number,
};



const Transaction = () => {

    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const navigate = useNavigate();
    const data = '';
    const [transaction, setTransaction] = useState<requestRow[]>([])
    const [totalSeriesFromSellersAmount, setTotalSeriesFromSellersAmount] = useState('')
    const [seriesUsedTotal, setSeriesUsedTotal] = useState('')
    const [upaidPayOnNextOrder, setUpaidPayOnNextOrder] = useState('')
    const [surplierPaidInvoices, setSurplierPaidInvoices] = useState('')
    const [suplierUnpaidInvoices, setSuplierUnpaidInvoices] = useState('')
    const [supplierUnpaidOnNextOder, setSupplierUnpaidOnNextOder] = useState('')
    const [countInvoicePaid, setCountInvoicePaid] = useState('')
    const [countInvoiceUnpaid, setCountInvoiceUnpaid] = useState('')
    const [paidOnNetOrderAmount, setPaidOnNetOrderAmount] = useState('')
    const [myEarnings, setMyEarnings] = useState('')
    const [pendingWithdraws, setPendingWithdraws] = useState('')
    const [paidOut, setPaidOut] = useState('')

    const [totalPage, setTotalPage] = useState(1);

    //Set request
    const [phone, setPhone] = useState('');
    const [id, setId] = useState('');
    const [nameSeller, steNameSeller] = useState('');

    useEffect(() => {
        if (sessionStorage.getItem('onktxonktx') == null) {
            navigate('/login')
        }
        loadInventory(1)
    }, []);

    function loadInventory(page: any) {
        api.getHttpRequest(`/partners/transactions?page=${page}`, data, 'GET').then((response) => {

            const json = response.data;
            if (json.status == 200) {
                setTransaction(json.data.results);
                setTotalSeriesFromSellersAmount(json.data.total_series_from_sellers_amount)
                setSeriesUsedTotal(json.data.series_used_total)
                setUpaidPayOnNextOrder(json.data.upaid_pay_on_next_order)
                setSurplierPaidInvoices(json.data.surplier_paid_invoices)
                setSuplierUnpaidInvoices(json.data.suplier_unpaid_invoices)
                setSupplierUnpaidOnNextOder(json.data.supplier_unpaid_on_next_oder)
                setCountInvoicePaid(json.data.count_invoice_paid)
                setCountInvoiceUnpaid(json.data.count_invoice_unpaid)
                setPaidOnNetOrderAmount(json.data.paid_on_net_order_amount)
                setMyEarnings(json.data.my_earnings)
                setPendingWithdraws(json.data.pending_withdraws)
                setPaidOut(json.data.paid_out)

                setTotalPage(json.count)

            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    // Créer une fonction qui retourne le bouton selon les données
    const getButton = (count_used: any, deposit_amount: any, reste: any, amount: any, onClick: () => void) => {

        // Définir les styles communs
        const buttonStyle: MyCustomCSS = {
            height: 25,
            padding: 0,
            width: 80,
            textAlign: 'center',
        };

        // Vérifier les conditions et retourner le bouton correspondant
        if (amount === reste) {
            return (
                <Button style={{ ...buttonStyle }} onClick={onClick}>
                    Request
                </Button>
            );
        } else if (count_used === deposit_amount) {
            <Button style={{ ...buttonStyle }}>
                PAID
            </Button>
        }
        else if (count_used !== deposit_amount) {
            if (count_used === reste) {
                return (
                    <Badge count={5} size="small" style={{ backgroundColor: 'red', marginRight: 10 }}>
                        <Button style={{ ...buttonStyle, backgroundColor: 'red', borderColor: 'red', color: '#ffffff' }} onClick={onClick}>
                            Request
                        </Button>
                    </Badge>
                );
            } else {
                return (
                    <Button style={{ ...buttonStyle }}>
                        Request
                    </Button>
                );
            }
        } else if (amount === count_used && amount === deposit_amount) {
            return (
                <Button style={{ ...buttonStyle, backgroundColor: '#39A945', borderColor: '#39A945', color: '#ffffff' }} onClick={onClick}>
                    Collected
                </Button>
            );
        }
    };


    //Modal Request
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    function showModal(phone: any, id_: any, name: string) {
        setOpen(true);
        setPhone(phone);
        setId(id_);
        steNameSeller(name);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="container-fluid1">

                <div className="row">
                    <div className="col-12">
                        <div>
                            <h2 className="header-title mb-1 font-30 border-none">All Transactions
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="mt-2">


                    {/* <div className='row col-md-12 d-flex' >
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <div>
                                    <LiaNetworkWiredSolid
                                        style={{
                                            backgroundColor: '#E5FAFB',
                                            height: 60,
                                            width: 60,
                                            borderRadius: 100,
                                            fontSize: 18,
                                            color: '#03C9D7',
                                            padding: 8
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>Total amount in  Sellers <br /> network</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {totalSeriesFromSellersAmount}</strong>
                                </div>

                                <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 4, marginLeft: 15 }} />

                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <div>
                                    <BsXDiamond
                                        style={{
                                            backgroundColor: '#FEC90F',
                                            height: 60,
                                            width: 60,
                                            borderRadius: 100,
                                            fontSize: 18,
                                            color: 'white',
                                            padding: 8
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 2 }}>Surplier PAID Invoices</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {surplierPaidInvoices}</strong><br />
                                    <label style={{
                                        backgroundColor: '#97CC30',
                                        color: '#ffffff', textAlign: 'center', paddingLeft: 5, paddingRight: 5
                                    }}>{countInvoicePaid} INVOICEES PAID</label>
                                </div>
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <div>
                                    <BsQuestionDiamond
                                        style={{
                                            backgroundColor: '#FFF4E5',
                                            height: 60,
                                            width: 60,
                                            borderRadius: 100,
                                            fontSize: 18,
                                            color: '#E36A76',
                                            padding: 8
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>Surplier UNPAID Invoices</p>

                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {suplierUnpaidInvoices}</strong><br />
                                    <button style={{
                                        backgroundColor: '#0B6AF8',
                                        color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                        height: 25, paddingBottom: 5, width: 60
                                    }}>PAY</button>

                                </div>
                                <Badge count={countInvoiceUnpaid}

                                    style={{ cursor: 'pointer', marginLeft: 14 }}
                                // size='strong'
                                />
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <div>
                                    <BsExclamationDiamond
                                        style={{
                                            backgroundColor: '#E7F6EE',
                                            height: 60,
                                            width: 60,
                                            borderRadius: 100,
                                            fontSize: 18,
                                            color: '#00BD8E',
                                            padding: 8
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>Surplier UNPAID Invoices</p>

                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {suplierUnpaidInvoices}</strong><br />
                                    <button style={{
                                        backgroundColor: '#0B6AF8',
                                        color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                        height: 25, paddingBottom: 5, width: 60
                                    }}>PAY</button>

                                </div>
                                <Badge count={countInvoiceUnpaid}

                                    style={{ cursor: 'pointer', marginLeft: 14 }}
                                // size='strong'
                                />
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <FcSimCardChip
                                    style={{
                                        backgroundColor: 'red',
                                        height: 60,
                                        width: 60,
                                        borderRadius: 100,
                                        fontSize: 18,
                                        color: '#FFFFFF',
                                        padding: 8
                                    }}
                                />
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>Total used pins</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {seriesUsedTotal}</strong>
                                </div>
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2' >
                            <div className='d-flex ml-0'>
                                <FaMoneyCheckDollar
                                    style={{
                                        backgroundColor: '#0B6AF8',
                                        height: 60,
                                        width: 60,
                                        borderRadius: 100,
                                        fontSize: 18,
                                        color: 'white',
                                        padding: 8
                                    }}
                                />
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>My Earnings</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {myEarnings}</strong><br />
                                    <label style={{
                                        backgroundColor: '#0B6AF8',
                                        color: '#ffffff', textAlign: 'center', paddingLeft: 5, paddingRight: 5
                                    }}>Withdraws</label>
                                </div>
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <LiaMoneyCheckSolid
                                    style={{
                                        backgroundColor: '#E7F6EE',
                                        height: 60,
                                        width: 60,
                                        borderRadius: 100,
                                        fontSize: 18,
                                        color: '#00BD8E',
                                        padding: 8
                                    }}
                                />
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>Total Vendors Earnings</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {pendingWithdraws}</strong><br />
                                    <label>From Feb 20,2023 at 11:25:05 pm</label>
                                </div>
                            </div>
                        </Card>
                        <Card className='col-md-3 mb-2'  >
                            <div className='d-flex ml-0'>
                                <FaMoneyCheckAlt
                                    style={{
                                        backgroundColor: '#FFF4E5',
                                        height: 60,
                                        width: 60,
                                        borderRadius: 100,
                                        fontSize: 18,
                                        color: '#E36A76',
                                        padding: 8
                                    }}
                                />
                                <div style={{ marginLeft: 10 }}>
                                    <p style={{ marginBottom: 5 }}>PAID Out</p>
                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {paidOut}</strong><br />
                                    <label>From Feb 20,2023 at 11:25:05 pm</label>
                                </div>
                            </div>
                        </Card>
                    </div> */}

                    <Card style={{ width: '100%' }}>
                        {
                            isMatch ? (
                                <>
                                    <div style={{ display: 'flex', marginBottom: 8 }}>
                                        <label style={{
                                            backgroundColor: '#4920E9',
                                            height: 50,
                                            width: 10,
                                            borderRadius: 10
                                        }}></label>
                                        <div style={{ marginLeft: 10 }}>
                                            <p style={{ marginBottom: 0, fontSize: 16 }}>Total amount in Sellers
                                            </p> network
                                            <strong style={{ marginBottom: 5, fontSize: 16 }}> Tsh: {totalSeriesFromSellersAmount}</strong>

                                        </div>

                                        <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 10, marginLeft: 45 }} />


                                    </div>

                                    <div style={{ display: 'flex' }}>

                                        <div style={{ display: 'flex', marginBottom: 5 }}>
                                            <label style={{
                                                backgroundColor: '#4920E9',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 1 }}>Total used pins</p>
                                                <strong style={{ marginBottom: 1, fontSize: 14 }}>Tsh:{seriesUsedTotal}</strong>
                                            </div>

                                        </div>
                                        
                                    </div> <hr />


                                    <div style={{ display: 'flex', marginBottom: 8 }}>
                                        <label style={{
                                            backgroundColor: '#2AB571',
                                            height: 50,
                                            width: 10,
                                            borderRadius: 10
                                        }}></label>
                                        <div style={{ marginLeft: 10 }}>
                                            <p style={{ marginBottom: 0, fontSize: 18 }}>Surplier PAID Invoices</p>
                                            <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {surplierPaidInvoices}</strong><br />
                                            <label style={{
                                                backgroundColor: '#97CC30',
                                                color: '#ffffff', textAlign: 'center', padding: 10
                                            }}>{countInvoicePaid} INVOICEES PAID</label>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', marginBottom: 5 }}>
                                            <label style={{
                                                backgroundColor: '#2AB571',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 0 }}>Suplier UNPAID Invoices</p>

                                                <strong style={{ fontSize: 14 }}>Tsh:{suplierUnpaidInvoices}</strong><br />
                                                <button style={{
                                                    backgroundColor: '#0B6AF8',
                                                    color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                                    height: 25, paddingBottom: 5, width: 60
                                                }}>PAY</button>

                                                <Badge count={countInvoiceUnpaid}

                                                    style={{ cursor: 'pointer', marginLeft: 5 }}
                                                
                                                />

                                            </div>

                                        </div>
                                        <div style={{ display: 'flex', marginLeft: 30 }}>
                                            <label style={{
                                                backgroundColor: '#2AB571',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 0 }}>Supplier UNPAID on Next Oder</p>
                                                <strong style={{ fontSize: 14 }}>Tsh:{supplierUnpaidOnNextOder}</strong><br />
                                                <button style={{
                                                    backgroundColor: '#0B6AF8',
                                                    color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                                    height: 25, paddingBottom: 5, width: 60
                                                }}>PAY</button>
                                                <Badge count={paidOnNetOrderAmount}

                                                    style={{ cursor: 'pointer', marginLeft: 5 }}
                                                />
                                            </div>

                                        </div>
                                    </div><hr />


                                    <div style={{ display: 'flex', marginBottom: 15 }}>
                                        <label style={{
                                            backgroundColor: '#2C71BA',
                                            height: 50,
                                            width: 10,
                                            borderRadius: 10
                                        }}></label>
                                        <div style={{ marginLeft: 10 }}>
                                            <p style={{ marginBottom: 5, fontSize: 18 }}>My Earnings</p>
                                            <div style={{ display: 'flex' }}>
                                                <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {myEarnings}</strong>
                                                <label style={{
                                                    backgroundColor: '#0B6AF8',
                                                    color: '#ffffff', textAlign: 'center', padding: 2,
                                                    marginLeft: 10
                                                }}>Withdraws</label>
                                            </div>

                                        </div>

                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', marginBottom: 15 }}>
                                            <label style={{
                                                backgroundColor: '#2C71BA',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 5 }}>Pending Withdraws</p>
                                                <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {pendingWithdraws}</strong><br />
                                                <label>From Feb 20,2023 at 11:25:05 pm</label>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{
                                                backgroundColor: '#2C71BA',
                                                height: 50,
                                                width: 10,
                                                borderRadius: 10
                                            }}></label>
                                            <div style={{ marginLeft: 10 }}>
                                                <p style={{ marginBottom: 5 }}>PAID Out</p>
                                                <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {paidOut}</strong><br />
                                                <label>From Feb 20,2023 at 11:25:05 pm</label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>

                                    <Row style={{ marginBottom: 15 }}>
                                        <Col span={6} >
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#4920E9',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Total amount in  Sellers <br /> network</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {totalSeriesFromSellersAmount}</strong>
                                                </div>

                                                <Badge count={'i'} size="small" style={{ backgroundColor: '#0B6AF8', marginTop: 4, marginLeft: 15 }} />


                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#4920E9',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Total used pins</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {seriesUsedTotal}</strong>
                                                </div>

                                            </div>
                                            
                                        </Col>

                                        <Col span={7} >
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#2AB571',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Surplier PAID Invoices</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {surplierPaidInvoices}</strong><br />
                                                    <label style={{
                                                        backgroundColor: '#97CC30',
                                                        color: '#ffffff', textAlign: 'center', paddingLeft: 5, paddingRight: 5
                                                    }}>{countInvoicePaid} INVOICEES PAID</label>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#2AB571',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Surplier UNPAID Invoices</p>

                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {suplierUnpaidInvoices}</strong><br />
                                                    <button style={{
                                                        backgroundColor: '#0B6AF8',
                                                        color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                                        height: 25, paddingBottom: 5, width: 60
                                                    }}>PAY</button>

                                                </div>
                                                <Badge count={countInvoiceUnpaid}

                                                    style={{ cursor: 'pointer', marginLeft: 14 }}
                                               
                                                />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <label style={{
                                                    backgroundColor: '#2AB571',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Surplier UNPAID bills out of time</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {supplierUnpaidOnNextOder}</strong><br />
                                                    <button style={{
                                                        backgroundColor: '#0B6AF8',
                                                        color: '#ffffff', borderRadius: 5, borderColor: '#0B6AF8',
                                                        height: 25, paddingBottom: 5, width: 60
                                                    }}>PAY</button>
                                                </div>
                                                <Badge count={paidOnNetOrderAmount}

                                                    style={{ cursor: 'pointer', marginLeft: 14 }}
                                                />
                                            </div>
                                        </Col>

                                        <Col span={7} >
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#2C71BA',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>My Earnings</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {myEarnings}</strong><br />
                                                    <label style={{
                                                        backgroundColor: '#0B6AF8',
                                                        color: '#ffffff', textAlign: 'center', paddingLeft: 5, paddingRight: 5
                                                    }}>Withdraws</label>
                                                </div>

                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 15 }}>
                                                <label style={{
                                                    backgroundColor: '#2C71BA',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>Total Vendors Earnings</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {pendingWithdraws}</strong><br />
                                                    <label>From Feb 20,2023 at 11:25:05 pm</label>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <label style={{
                                                    backgroundColor: '#2C71BA',
                                                    height: 50,
                                                    width: 10,
                                                    borderRadius: 10
                                                }}></label>
                                                <div style={{ marginLeft: 10 }}>
                                                    <p style={{ marginBottom: 5 }}>PAID Out</p>
                                                    <strong style={{ marginBottom: 1, fontSize: 16 }}>Tsh: {paidOut}</strong><br />
                                                    <label>From Feb 20,2023 at 11:25:05 pm</label>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </>
                            )
                        }


                    </Card>

                </div>

                <div className="mt-2">

                    <Card style={{ width: '100%' }}>
                        <Tabs defaultActiveKey="tab1" tabPosition="top" >
                            <Tabs.TabPane style={{ fontSize: 20 }} tab="In Network" key="tab1">

                                <BackToCollect />

                            </Tabs.TabPane>
                            <Tabs.TabPane style={{ fontSize: 20 }} tab="Vendors Payout" key="tab2">

                                <PayNext />

                            </Tabs.TabPane>
                            {/* <Tabs.TabPane style={{ fontSize: 20 }} tab="Partners Payout" key="tab3">

                                <SurplierPayout />
                                
                            </Tabs.TabPane> */}
                            {/* <Tabs.TabPane style={{ fontSize: 20 }} tab="My Earning" key="tab4">

                                <Earning />
                                
                            </Tabs.TabPane> */}
                        </Tabs>


                    </Card>

                </div>


            </div>



        </>
    )
}

export default Transaction